<script>
    // -- IMPORTS

    import { getStorageImagePath } from "$src/lib/storage";
    import { bookedPropertyStore } from "$src/lib/store/bookingStore";
    import { getLocalizedText, getLocalizedTextBySlug } from "senselogic-gist";
    import PropertyMiniCard from "../../element/PropertyMiniCard.svelte";
    import Alert from "../../element/Alert.svelte";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import Loading from "../../element/Loading.svelte";
    import RadioCardGroup from "../../element/RadioCardGroup.svelte";
    import Switch from "senselogic-flow/Switch.svelte";
    import AccountBankingInformationCardModal from "../account/AccountBankingInformationCardModal.svelte";
    import CheckoutPagePriceRules from "./CheckoutPagePriceRules.svelte";
    import ModalButton from "../../modal/ModalButton.svelte";
    import { formatPrice } from "$src/lib/base";

    // -- VARIABLES

    export let price;
    export let nightCount;
    export let compensation;
    export let donation;
    export let totalPrice;
    export let hasInstantBooking;
    export let paymentMethodArray;
    export let selectedPaymentMethod;
    export let isLoading;
    export let isSubmitting = false;
    export let populatePaymentMethodArrayFromPaymentMethodMap = ( paymentMethodMap ) => {};
    export let handleSubmit = () => {};
    let isCreateCardModalOpen = false;
    let payInInstallments = false;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../../mixin.styl";
    @import "../../../../constant.styl";

    // -- CLASSES

    .step-container
    {
        width: 100%;
        padding: 0 1.5rem 1.5rem;

        +media( desktop )
        {
            display: flex;
            gap: 3rem;
            align-items:flex-start;
        }
    }

    .checkout-page-book-now-payment-methods
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .checkout-page-book-now-installment-plan
    {
        margin-bottom: 0.75rem;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .checkout-page-toggler-container
    {
        width: 100%;
        border-bottom: 1px solid lightGrayBorderColor;
        padding: 1rem 0;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .checkout-page-book-now-terms
    {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .mobile-price-rules-container
    {
        width: 100%;

        +media( desktop )
        {
            display: none;
        }
    }

    .desktop-price-rules-container
    {
        display: none;

        +media( desktop )
        {
            min-width: 27.875rem;
            border-radius: 1.5rem;
            padding: 1.5rem;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            background-color: grayColor950;
            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }
</style>

<div class="step-container">
    <div class="display-flex flex-direction-column gap-150">
        <PropertyMiniCard
            route="/property/{ $bookedPropertyStore.id }"
            image={ getStorageImagePath( $bookedPropertyStore.imagePath, 640 ) }
            label={ getLocalizedText( $bookedPropertyStore.title )}
            text=""
        />
        <Alert
            type="informative"
            text={ getLocalizedTextBySlug( 'booking-checkout-page-donation-alert', $languageTagStore ) }
        />
        <div class="mobile-price-rules-container">
            <CheckoutPagePriceRules
                price={ price }
                nightCount={ nightCount }
                compensation={ compensation }
                totalPrice={ totalPrice }
                bind:donation={ donation }
            />
        </div>
        { #if hasInstantBooking }
            <div class="checkout-page-book-now-payment-methods">
                    <div class="font-size-100 font-weight-700 color-gray-100">
                        { getLocalizedTextBySlug( 'booking-checkout-page-payment-method', $languageTagStore ) }
                    </div>
                { #if isLoading }
                    <Loading />
                { :else }
                    <RadioCardGroup
                        bind:optionArray={ paymentMethodArray }
                        bind:selected={ selectedPaymentMethod }
                    />
                { /if }
                <ModalButton
                    variant="light"
                    on:click={ () => { isCreateCardModalOpen = true; isLoading = true } }
                >
                    <div class="add-icon size-150" />
                    { getLocalizedTextBySlug( 'booking-checkout-page-add-new-payment', $languageTagStore ) }
                </ModalButton>
            </div>
            <div class="checkout-page-book-now-installment-plan">
                    <div class="checkout-page-toggler-container">
                        <div class="font-size-90 font-weight-700 color-black checkout-page-toggler-label">
                            { getLocalizedTextBySlug( 'booking-checkout-page-payment-in-two-times-label', $languageTagStore ) }
                        </div>
                    <Switch value={ payInInstallments }  />
                </div>
                <div class="margin-top-75 margin-bottom-75">
                        <div class="font-size-90 font-weight-500 color-gray-300">
                            { getLocalizedTextBySlug( 'booking-checkout-page-payment-in-two-times-description', $languageTagStore ) }
                        </div>
                </div>
            </div>
        { /if }
        <div class="checkout-page-book-now-terms">
                <div class="font-size-100 font-weight-700 color-gray-100">
                    { getLocalizedTextBySlug( 'booking-checkout-page-terms-of-sales', $languageTagStore ) }
                </div>
            <div class="font-size-90 font-weight-500 color-gray-300">
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    electronic typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of
                    Lorem Ipsum.
                </div>
        </div>
    </div>
    <div class="desktop-price-rules-container">
        <div class="font-size-100 font-weight-700 color-gray-100">
            { getLocalizedTextBySlug( 'summary-label', $languageTagStore ) }
        </div>
        <CheckoutPagePriceRules
            price={ price }
            nightCount={ nightCount }
            compensation={ compensation }
            totalPrice={ totalPrice }
            bind:donation={ donation }
        />
        <ModalButton
            text=
            "
                { getLocalizedTextBySlug( 'booking-checkout-page-book-now-for', $languageTagStore ) }
                { formatPrice( totalPrice, $languageTagStore ) }
            "

            isLoading={ isSubmitting }
            on:click={ handleSubmit }
        />
    </div>
</div>
<AccountBankingInformationCardModal
    isOpen={ isCreateCardModalOpen }
    onClose={
        ( card ) =>
        {
            populatePaymentMethodArrayFromPaymentMethodMap(
                {
                    cardArray: card ? [ card ] : [],
                    walletArray: [],
                    bankAccountArray: []
                }
                );
            selectedPaymentMethod = paymentMethodArray[ paymentMethodArray.length - 1 ].value;
            isCreateCardModalOpen = false;
            isLoading = false;
        }
    }
/>
