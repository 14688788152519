<script>
    // -- IMPORTS

    import { navigate } from 'svelte-routing';

    // -- STATEMENTS

    let previousPage = '/';

    // -- FUNCTIONS

    function goBack(
        )
    {
        navigate( previousPage );
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .go-back
    {
        height: 2rem;
        width: 2rem;
        border-radius: 0.75rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: grayColor950;
    }
</style>

<button class="gray-left-caret-icon size-150 go-back" on:click={ goBack }/>
