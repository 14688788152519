<script>
    // -- IMPORTS

    import { onMount, onDestroy, afterUpdate } from 'svelte';
    import { slide } from 'svelte/transition';
    import 'leaflet/dist/leaflet.css';
    import 'leaflet.markercluster/dist/MarkerCluster.css';
    import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore.js';
    import { selectedLocationStore,defaultLocationStore, isDesktopMapOpenStore, isDesktopListOpenStore, isMobileMapOpenStore, isMobileListOpenStore } from '$store/locationStore';
    import { languageTagStore } from '$store/languageTagStore';
    import PropertyCard from '$component/page/properties/PropertyCard.svelte';

    // -- VARIABLES

    export let propertyArray;
    export let isLoading;

    let previousPropertyArray = [];
    let mapElement;
    let map;
    let markers;
    let leaflet;

    // -- FUNCTIONS

    const initializeMap = async () => {
            leaflet = await import( 'leaflet' );

            if ( !map )
            {
                map = leaflet.map(
                        mapElement,
                        {
                            zoomControl: false,
                            attributionControl: false,
                            maxZoom: 18,
                            minZoom: 5
                        }
                        );
            }

            await import( 'leaflet.markercluster/dist/leaflet.markercluster-src.js' );

            if ( propertyArray && propertyArray.length > 0 )
            {
                fitMapViewToProperties();
            }
            else
            {
                if ( $selectedLocationStore )
                {
                    if ( $selectedLocationStore.latitude && $selectedLocationStore.longitude )
                    {
                        map = leaflet.map().setView( [ $selectedLocationStore.latitude, $selectedLocationStore.longitude ], 10 );
                    }
                }
                else
                {
                    map.setView( [ $defaultLocationStore.latitude, $defaultLocationStore.longitude], 3 );
                }
            }

            leaflet.tileLayer( 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png' ).addTo( map );

            markers = new L.MarkerClusterGroup(
                {
                    iconCreateFunction: function( cluster )
                    {
                        var childCount = cluster.getChildCount();

                        return new L.DivIcon(
                            {
                                html: '<div><span>' + childCount + '</span></div>',
                                className: 'font-size-75 font-weight-700 color-white properties-map-cluster-icon',
                                iconSize: new L.Point( 40, 40 )
                            }
                            );
                    }
                }
                );

            if ( propertyArray && propertyArray.length > 0 )
            {
                for ( let property of propertyArray )
                {
                    let propertyIconPrice;

                    if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForShortTerm' ] )
                    {
                        propertyIconPrice = property.shortTermDailyPrice;
                    }
                    else if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForLongTerm' ] )
                    {
                        propertyIconPrice = property.longTermMonthlyPrice;
                    }
                    else
                    {
                        propertyIconPrice = property.shortTermDailyPrice;
                    }

                    let marker = leaflet.marker( [ property.latitude, property.longitude ],
                    {
                        icon: leaflet.divIcon(
                            {
                                className: 'custom-div-icon',
                                html: `<div class="properties-map-icon">${ propertyIconPrice }€</div>`,
                                iconSize: [ 40, 40 ],
                                iconAnchor: [ 15, 42 ]
                            }
                            )
                    }
                    );

                    marker.bindPopup( `<div id="property-container-${ property.id }"></div>`,).on( 'popupopen', function(
                        )
                    {
                        let container = document.getElementById( `property-container-${ property.id }` );

                        if ( container )
                        {
                            new PropertyCard(
                            {
                                target: container,
                                props:
                                {
                                    property
                                }
                            }
                            );
                        }
                    }
                    );

                    markers.addLayer( marker );
                }
            }

            map.addLayer( markers );
        };

    // ~~

    onMount(
        async () =>
        {
            if ( !isLoading )
            {
                initializeMap();
            }
        }
    );

    // ~~

    $: if ( !isLoading )
    {
        initializeMap();
    }

    // ~~

    onDestroy(
        async () =>
        {
            if ( map )
            {
                map.remove();
            }
        }
        );

    // ~~

    let updateMapView =
        () =>
        {
            if ( map && markers )
            {
                markers.clearLayers();

                for ( let property of propertyArray )
                {
                    let propertyIconPrice;
                    if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForShortTerm' ] )
                    {
                        propertyIconPrice = property.shortTermDailyPrice;
                    }
                    else if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForLongTerm' ] )
                    {
                        propertyIconPrice = property.longTermMonthlyPrice;
                    }
                    else
                    {
                        propertyIconPrice = property.shortTermDailyPrice;
                    }

                    let marker = leaflet.marker( [ property.latitude, property.longitude ],
                    {
                        icon: leaflet.divIcon(
                            {
                                className: 'custom-div-icon',
                                html: `<div class="properties-map-icon">${ propertyIconPrice }€</div>`,
                                iconSize: [ 40, 40 ],
                                iconAnchor: [ 15, 42 ]
                            }
                            )
                    }
                    );

                    markers.addLayer( marker );
                }
            }
        }

    // ~~

    afterUpdate(
        () =>
        {
            if ( JSON.stringify( previousPropertyArray ) !== JSON.stringify( propertyArray ) )
            {
                updateMapView();
            }

            previousPropertyArray = [ ...propertyArray ];
        }
        );

    // ~~

    let fitMapViewToProperties = () =>
    {
        if ( map && propertyArray && propertyArray.length > 0 )
        {
            let bounds = leaflet.latLngBounds();

            for ( let property of propertyArray )
            {
                bounds.extend( [ property.latitude, property.longitude ] );
            }

            map.fitBounds( bounds );
        }
    };

    // ~~

    $: if ( $isDesktopMapOpenStore === true || $isMobileMapOpenStore === true )
    {
        if ( $selectedLocationStore )
        {
            if ( map )
            {
                map.setView( [ $selectedLocationStore.latitude, $selectedLocationStore.longitude ], 10 );
            }
        }
        else
        {
            fitMapViewToProperties();
        }

        if ( map )
        {
            setTimeout(
                () =>
                {
                    map.invalidateSize();
                },
                100
                );
        }
    }

    // ~~

    $: if ( propertyArray )
    {
        updateMapView();
        fitMapViewToProperties();
    }

    // ~~

    $: if ( $isDesktopListOpenStore === false )
    {
        if ( $selectedLocationStore )
        {
            if ( map )
            {
                map.setView( [ $selectedLocationStore.latitude, $selectedLocationStore.longitude ], 10 );
            }
        }
        else
        {
            updateMapView();
            fitMapViewToProperties();
        }

        if ( map )
        {
            map.invalidateSize();
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    :global( .leaflet-popup-content )
    {
        margin: unset;
        min-height: unset;

        line-height: unset;
        font-size: unset;
        font-size: unset;
    }

    :global( .leaflet-popup-close-button )
    {
        display: none;
    }

    :global( .leaflet-fade-anim .leaflet-map-pane .leaflet-popup )
    {
        bottom: 2rem !important;
    }

    :global( .leaflet-popup-content-wrapper )
    {
        width: 20rem;
        max-width: calc( var( --viewport-width ) - 3rem );
        border-radius: 0.5rem;
        padding: 0.5rem;

        background: pageBackgroundColor;
    }

    :global( .leaflet-popup-content-wrapper .leaflet-popup-content )
    {
        width: 100% !important;
    }

    :global( .leaflet-popup-tip )
    {
        display: none;
    }

    .properties-map
    {
        height: calc( var( --viewport-height ) - 12.5rem );

        +media( desktop )
        {
            position: relative;

            height: calc( var( --viewport-height ) - 8.5rem );
            width: 100%;
        }
    }

    .properties-map.is-desktop-list-open.is-desktop-map-open
    {
        +media( desktop )
        {
            height: calc( var( --viewport-height ) - 4.5rem );
        }
    }

    .properties-map.is-desktop-map-open
    {
        +media( desktop )
        {
            height: calc( var( --viewport-height ) - 14.5rem );
        }
    }

    .properties-map-container
    {
        height: 100%;
    }

    :global( .properties-map-cluster-icon )
    {
        border-radius: 50%;

        display: flex !important;
        justify-content: center;
        align-items: center;

        background-color: blueColor;
    }

    :global( .properties-map-icon )
    {
        min-width: 3rem;
        border-radius: 0.75rem;
        padding: 0.1875rem 0.5rem;

        background-color: blueColor;

        text-align: center;
        color: grayColor950 !important;
    }

    .properties-map-button, .properties-map-close-button
    {
        z-index: 998;
        position: absolute;
        bottom: 0rem;
        transform: translate( -50%, -50% );

        border-radius: 0.5rem;
        padding: 0.875rem 2.5rem;

        display: none;

        +media( desktop )
        {
            display: flex;
            gap: 0.5rem;
            align-items: center;
        }
    }

    .properties-map-button
    {
        bottom: 0rem;
        left: 50%;

        border: 1px solid blueColor;

        background-color: whiteColor;

        transition: border-color, color 400ms ease-in-out;

        +media( desktop )
        {
            display: none;
        }
    }

    .properties-map-button:hover
    {
        +media( desktop )
        {
            border-color: blueColor300;

            color: blueColor300;
        }
    }

    .properties-map-close-button
    {
        left: 16%;

        background-color: blueColor300;

        transition: background-color 400ms ease-in-out;
        >div
        {
            transition: background-color 400ms ease-in-out;
        }
    }

    .properties-map-close-button:hover
    {
        background-color: blueColor500;

        color: whiteColor;
    }

    .properties-map.is-mobile-map-open .properties-map-button
    {
        display: flex;

        +media( desktop )
        {
            display: none;
        }
    }

    .properties-map.is-desktop-map-open .properties-map-button
    {
        +media( desktop )
        {
            display: flex;
        }
    }

    .properties-map.is-desktop-map-open .properties-map-close-button
    {
        +media( desktop )
        {
            display: none;
        }
    }

    .properties-map.is-desktop-map-open.is-desktop-list-open .properties-map-close-button
    {
        +media( desktop )
        {
            display: flex;
        }
    }

    .properties-map.is-desktop-list-open.is-desktop-map-open .properties-map-button
    {
        +media( desktop )
        {
            display: none;
        }
    }
</style>

<div
    class="properties-map"
    class:is-mobile-map-open={ $isMobileMapOpenStore }
    class:is-mobile-list-open={ $isMobileListOpenStore }
    class:is-desktop-map-open={ $isDesktopMapOpenStore }
    class:is-desktop-list-open={ $isDesktopListOpenStore }
    transition:slide
>
    <div class="properties-map-container" bind:this={ mapElement }></div>
    { #if propertyArray.length > 0 }
        <button
            class="font-size-90 font-weight-700 color-blue properties-map-button"
            on:click={ () => { $isDesktopMapOpenStore = false; $isDesktopListOpenStore = true; $isMobileMapOpenStore = false; } }
        >
            { propertyArray.length } { getLocalizedTextBySlug( 'properties-label', $languageTagStore ) }
        </button>
    { /if }
    { #if propertyArray.length > 0 }
        <button
            class="font-size-90 font-weight-700 color-white properties-map-close-button"
            on:click={ () => { $isDesktopMapOpenStore = false; $isMobileMapOpenStore = false; $isMobileListOpenStore = true; $isDesktopListOpenStore = true; } }
        >
            <div class="white-map-icon size-150"/>
            { getLocalizedTextBySlug( 'property-list-close-map-button', $languageTagStore ) }
        </button>
    { /if }
</div>
