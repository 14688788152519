<script>
    // -- IMPORTS

    import { getLocalizedText, getLocalizedTextBySlug, isArray } from 'senselogic-gist';
    import { languageTagStore } from '$store/languageTagStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { countryArrayStore } from '$store/countryArrayStore';
    import Alert from '$component/element/Alert.svelte';
    import PersonalDetailInput from '$component/page/account/PersonalDetailInput.svelte';
    import PhoneNumberInputs from '$component/page/account/PhoneNumberInputs.svelte';
    import CountryFilter from '$component/page/account/CountryFilter.svelte';
    import CityFilter from '$component/page/account/CityFilter.svelte';
    import AccountSupportingDocuments from './AccountSupportingDocuments.svelte';
    import { genderArrayStore, genderByIdMapStore } from '$src/lib/store/genderStore';
    import LabelledSelect from '../../element/LabelledSelect.svelte';
    import LabelledInput from '../../element/LabelledInput.svelte';

    // -- VARIABLES

    $: profile = $profileSignedInStore;
    let country = $countryArrayStore.filter( country => country.code === $profileSignedInStore.countryCode )[ 0 ];
</script>

<style lang='stylus'>
    // -- IMPORTS

    @import '../../../../constant.styl';

    // -- CLASSES

    :global( .personal-detail-button )
    {
        margin: 1.5rem 0 2rem;
        width: 100%;
        border: 2px solid greenColor;
        border-radius: 0.75rem;
        padding: 1rem 0;
    }
</style>

<div class="personal-detail-group">
    <div class="font-size-100 font-weight-700 color-black">
        { getLocalizedTextBySlug( 'personal-information-page-title', $languageTagStore ) }
    </div>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'personal-information-legal-name', $languageTagStore ) }
        initialValue={ profile.firstName + ' ' + profile.lastName }
        editable={ true }
        helper={ getLocalizedTextBySlug( 'personal-information-legal-name-helper', $languageTagStore ) }
    >
        <LabelledInput
            label={ getLocalizedTextBySlug( 'personal-information-first-name', $languageTagStore ) }
            name="first-name"
            placeholder={ getLocalizedTextBySlug( 'personal-information-first-name', $languageTagStore ) }
            value={ profile.firstName }
        />
        <LabelledInput
            label={ getLocalizedTextBySlug( 'personal-information-last-name', $languageTagStore ) }
            name="last-name"
            placeholder={ getLocalizedTextBySlug( 'personal-information-last-name', $languageTagStore ) }
            value={ profile.lastName }
        />
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'personal-information-email-address', $languageTagStore ) }
        initialValue={ profile.email }
        helper={ getLocalizedTextBySlug( 'personal-information-email-address-helper', $languageTagStore ) }
        editable={ true }
    >
        <LabelledInput
            label={ getLocalizedTextBySlug( 'personal-information-email', $languageTagStore ) }
            placeholder={ getLocalizedTextBySlug( 'personal-information-email', $languageTagStore ) }
            name="email"
            type="email"
            value={ profile.email }
        />
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'gender-label', $languageTagStore ) }
        initialValue={ profile.genderId ? getLocalizedText( $genderByIdMapStore[ profile.genderId ].name, $languageTagStore ) : '' }
        editable={ true }
    >
        <LabelledSelect
            name="gender-id"
            label={ getLocalizedTextBySlug( 'gender-label' ) }
            value={ profile.genderId }
            variant="contained"
        >
           { #each $genderArrayStore as gender }
                <option value={ gender.id }>
                    { getLocalizedText( gender.name, $languageTagStore ) }
                </option>
           { /each }
        </LabelledSelect>
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'auth-phone-number-placeholder', $languageTagStore ) }
        initialValue="{ profile.phonePrefix ? profile.phonePrefix : '' } { profile.phoneNumber ? profile.phoneNumber : ''}"
        editable={ true }
        helper={ getLocalizedTextBySlug( 'personal-information-email-address-helper', $languageTagStore ) }
    >
        <PhoneNumberInputs profile={ profile } />
    </PersonalDetailInput>
</div>
<div class="personal-detail-group">
    <div class="font-size-100 font-weight-700 color-black">
        { getLocalizedTextBySlug( 'personal-information-address-of-residence', $languageTagStore ) }
    </div>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'personal-information-country', $languageTagStore ) }
        initialValue={ country ? getLocalizedText( country.name, $languageTagStore ) : ''  }
        editable={ true }
    >
        <CountryFilter />
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'personal-information-address', $languageTagStore ) }
        initialValue={ profile.addressLine1 }
        editable={ true }
        helper={ getLocalizedTextBySlug( 'personal-information-residence-address-helper', $languageTagStore ) }
    >
        <LabelledInput
            label={ getLocalizedTextBySlug( 'personal-information-address', $languageTagStore ) }
            name="address-line-1"
            placeholder={ getLocalizedTextBySlug( 'personal-information-address', $languageTagStore ) }
            value={ profile.addressLine1 }
        />
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'personal-information-city', $languageTagStore ) }
        initialValue={ $profileSignedInStore.cityName }
        editable={ true }
        helper={ getLocalizedTextBySlug( 'personal-information-city-helper', $languageTagStore ) }
    >
        <CityFilter />
    </PersonalDetailInput>
    { #if $profileSignedInStore.governmentIdValidationStatusId === 'verified' }
        <div class="margin-top-150 margin-bottom-150">
            <Alert
                type="success"
                text={ getLocalizedTextBySlug( 'personal-information-proof-of-identity-verified', $languageTagStore ) }
            />
        </div>
    { :else if $profileSignedInStore.governmentId }
        <div class="margin-top-150 margin-bottom-150">
            <Alert
                type="info"
                text={ getLocalizedTextBySlug( 'personal-information-proof-of-identity-pending', $languageTagStore ) }
            />
        </div>
    { :else }
        { #if $profileSignedInStore.governmentIdValidationStatusId === 'declined' }
            <div class="margin-top-150 margin-bottom-150">
                <Alert
                    type="error"
                    text={ getLocalizedTextBySlug( 'personal-information-proof-of-identity-declined', $languageTagStore ) }
                />
            </div>
        { :else }
            <div class="margin-top-150 margin-bottom-150">
                <Alert
                    type="warning"
                    text={ getLocalizedTextBySlug( 'personal-information-proof-of-identity-warning', $languageTagStore ) }
                />
            </div>
        { /if }
        <AccountSupportingDocuments showDocumentList={ false }/>
    { /if }
</div>
