<script>
    // -- IMPORTS

    import { navigate } from "svelte-routing";
    import { getLocalizedTextBySlug } from "senselogic-gist";
    import { languageTagStore } from "$store/languageTagStore";
    import ModalActions from "$component/modal/ModalActions.svelte";
    import ModalButton from "$component/modal/ModalButton.svelte";
    import ModalContent from "$component/modal/ModalContent.svelte";
    import ModalHeader from "$component/modal/ModalHeader.svelte";
    import ModalRoot from "$component/modal/ModalRoot.svelte";

    // -- VARIABLES

    export let isOpen = false;
</script>

<ModalRoot bind:isOpen={ isOpen }>
    <ModalHeader
        title={ getLocalizedTextBySlug( 'property-rental-verify-identity-label', $languageTagStore ) }
        onClose={ () => isOpen = false }
    />
    <ModalContent>
        <div class="font-size-100 font-weight-700 color-gray-100">
            { getLocalizedTextBySlug( 'property-rental-we-have-not-verified-your-identity-label', $languageTagStore ) }
        </div>
        <div class="font-size-90 font-weight-500 color-gray-300">
            { getLocalizedTextBySlug( 'property-rental-we-have-not-verified-your-identity-text', $languageTagStore ) }
        </div>
    </ModalContent>
    <ModalActions>
        <ModalButton
            text={ getLocalizedTextBySlug( 'property-rental-upload-id-document-label', $languageTagStore ) }
            on:click={ () => navigate( '/dashboard/supporting-document' ) }
        />
    </ModalActions>
</ModalRoot>
