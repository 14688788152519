<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { clickOutside } from '$lib/base';
    import { updateUrlParameter } from '$lib/url';
    import { isOnboardingCompletedStore } from '$store/onboardingStore';
    import { filterParameterByKeyMapStore, updatePropertyParameters } from '$store/filterParameterByKeyMapStore';
    import OnboardingTermType from '$component/onboarding/OnboardingTermType.svelte';
    import OnboardingLocation from '$component/onboarding/OnboardingLocation.svelte';
    import OnboardingDate from '$component/onboarding/OnboardingDate.svelte';
    import OnboardingGuest from '$component/onboarding/OnboardingGuest.svelte';
    import OnboardingBudget from '$component/onboarding/OnboardingBudget.svelte';
    import OnboardingPropertyType from '$component/onboarding/OnboardingPropertyType.svelte';

    // -- VARIABLES

    let currentOnboardingStep = 0;

    let onboardingSteps =
        [
            { step: 0, component: OnboardingTermType },
            { step: 1, component: OnboardingLocation },
            { step: 2, component: OnboardingDate },
            { step: 3, component: OnboardingGuest },
            { step: 4, component: OnboardingBudget },
            { step: 5, component: OnboardingPropertyType }
        ];

    let longTermSteps = [ onboardingSteps[ 0 ], onboardingSteps[ 1 ], onboardingSteps[ 5 ], onboardingSteps[ 4 ] ];
    let currentLongTermStep = 0;

    // -- FUNCTIONS

    function goToNextStep(
        )
    {
        if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForShortTerm' ] )
        {
            if ( currentOnboardingStep < 4 )
            {
                currentOnboardingStep += 1;
            }
            else
            {
                localStorage.setItem( 'isOnboardingCompleted', 'true' );
                isOnboardingCompletedStore.set( true );

                updateUrlParameter(
                    $filterParameterByKeyMapStore
                );
            }
        }
        else if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForLongTerm' ] )
        {
            if ( currentLongTermStep < longTermSteps.length - 1 )
            {
                currentLongTermStep += 1;
                currentOnboardingStep = longTermSteps[ currentLongTermStep ].step;
            }
            else
            {
                localStorage.setItem( 'isOnboardingCompleted', 'true' );
                isOnboardingCompletedStore.set( true );

                updateUrlParameter(
                    $filterParameterByKeyMapStore
                );
            }
        }
        else
        {
            updatePropertyParameters( { [ 'isAvailableForLongTerm' ]: true } );
            if ( currentOnboardingStep < 4 )
            {
                currentOnboardingStep += 1;
            }
        }
    }

    // ~~

    onMount(
        () =>
        {
            let isOnboardingCompleted = localStorage.getItem( 'isOnboardingCompleted' );

            if ( isOnboardingCompleted === 'true' )
            {
                isOnboardingCompletedStore.set( true );
            }
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    :global( .onboarding >div:first-child )
    {
        z-index: 999;
        position: fixed;
        bottom: 0;
        left: 0;

        height: var( --viewport-height );
        width: var( --viewport-width );
        max-width: var( --viewport-width );
        max-width: 100vw;

        background-color: grayColor900;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        -ms-overflow-style: none;
        overflow: hidden;
        overflow-y: hidden;
        overflow-x: hidden;
        scrollbar-width: none;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            top: 50%;
            left: 50%;
            transform: translate( -50%, -50% );

            height: 36.875rem;
            width: calc( var( --viewport-width ) - 3rem );
            max-width: 27.875rem;
            border-radius: 1.5rem;
        }
    }

    :global( .onboarding.onboarding-1 >div:first-child ),
    :global( .onboarding.onboarding-4 >div:first-child )
    {
        background-color: #DDFCF3;
    }

    :global( .onboarding.onboarding-2 >div:first-child )
    {
        background-color: #E8F1FF;
    }

    :global( .onboarding.onboarding-3 >div:first-child )
    {
        background-color: #FFECEA;
    }

    :global( .onboarding.onboarding-5 >div:first-child )
    {
        background-color: #E8F1FF;
    }

    :global( .onboarding >div:first-child::before )
    {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        padding-top: 32vh;

        display: block;

        content: '';
        background-size: cover;

        +media( desktop )
        {
            display: none;
        }
    }

    :global( .onboarding >div >div:first-child:not( .onboarding-term-type-container, .onboarding-splash-container ), .onboarding >div >form:first-child:not( .onboarding-term-type-container, .onboarding-splash-container ) )
    {
        z-index: 9999;
        position: relative;

        overflow-y: auto;
        height: 100%;
        width: 90%;
        border-radius: 1rem 1rem 0rem 0rem;
        padding: 2rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        background-color: grayColor900;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        -ms-overflow-style: none;
        scrollbar-width: none;

        +media( desktop )
        {
            width: 100%;
        }
    }

    :global( .onboarding >div >div:first-child:not( .onboarding-term-type-container, .onboarding-splash-container )::-webkit-scrollbar, .onboarding >div >form:first-child:not( .onboarding-term-type-container, .onboarding-splash-container )::-webkit-scrollbar ),
    {
        display: none;
    }

    :global( .onboarding >div:not( .onboarding-term-type, .onboarding-splash )  )
    {
        padding-top: 30vh;

        +media( desktop )
        {
            padding-top: unset;
        }
    }
</style>

{ #if !$isOnboardingCompletedStore }
    <div class="modal-overlay"></div>
    <div
        class="onboarding onboarding-{ currentOnboardingStep }"
        use:clickOutside
        on:clickOutside={ () => isOnboardingCompletedStore.set( true ) }
    >
        { #each onboardingSteps as { step, component } ( step ) }
            { #if step === currentOnboardingStep }
                <svelte:component
                    this={ component }
                    goToNextStep={ goToNextStep }
                />
            { /if }
        { /each }
    </div>
{ /if }
