<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { updatePropertyParameters, updateLocationParameters } from '$store/filterParameterByKeyMapStore.js';
    import FilterLocation from '$component/filter/FilterLocation.svelte';

    // -- VARIABLES

    export let goToNextStep;

    // -- FUNCTIONS

    function handleCityIdSelected(
        event
        )
    {
        updatePropertyParameters( { 'cityId': event.detail } );
        goToNextStep();
    }

    // ~~

    function handleCountryCodeSelected(
        event
        )
    {
        updatePropertyParameters( { 'countryCode': event.detail } );
    }

    // ~~

    function handleCurrentCoordinatesSelected(
        event
        )
    {
        updateLocationParameters( { 'currentCoordinates': event.detail } );
    }
</script>

<style>
    .onboarding-location::before
    {
        background: url( '/image/onboarding/onboarding_location.png' ) no-repeat center center;
    }
</style>

<div class="onboarding-location">
    <div class="onboarding-location-content">
        <div class="font-size-150 font-weight-600 color-gray-100 onboarding-location-title">
            { getLocalizedTextBySlug( 'location-title' ) }
        </div>
        <FilterLocation
            on:cityIdSelected={ handleCityIdSelected }
            on:countryCodeSelected={ handleCountryCodeSelected }
            on:currentCoordinatesSelected={ handleCurrentCoordinatesSelected }
        />
    </div>
</div>
