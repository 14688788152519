<script>
    // -- IMPORTS

    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { languageTagStore } from '$store/languageTagStore';
    import { countryArrayStore } from '$store/countryArrayStore';
    import { profileSignedInStore } from '$store/profileStore';

    // -- VARIABLES

    let country = $countryArrayStore.filter( country => country.code === $profileSignedInStore.countryCode )[ 0 ];
    let countryCode = $profileSignedInStore.countryCode;
    let countrySearchTerm = '';
    $: filteredCountryArray = $countryArrayStore.filter( option => option.name.toLowerCase().includes( countrySearchTerm.toLowerCase() ) );

    // -- FUNCTIONS

    function handleCountryChange(
        )
    {
        if ( country )
        {
            countrySearchTerm = getLocalizedText( country.name, $languageTagStore );
        }
    }

    // -- STATEMENTS

    $:
    {
        country;
        handleCountryChange();
    }

    // ~~

    handleCountryChange();
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';

    // -- CLASSES

    .country-select-modal-content
    {
        position: relative;

        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .country-select-search-input-container
    {
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 1rem 0.75rem;

        display: flex;
        gap: 0.5rem;
        align-items: center;

        background: grayColor950;

        transition: all 200ms ease-in-out;
        &:focus-within
        {
            border-color: greenColor900;

            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }

    .country-select-search-input
    {
        outline: none;
        border: none;
        border-radius: unset;
        padding: unset;

        background-color: transparent;

        font-size: 0.9rem;
        color: grayColor100;
    }

    .country-select-country-radio-input-flag
    {
        height: 1.25rem;
        width: 1.75rem;
    }

    .country-select-radio-group
    {
        -ms-overflow-style: none;
        position: absolute;
        top: 4rem;

        overflow-y: auto;
        scrollbar-width: none;
        height: 8rem;
        width: 100%;
        width: 100%;
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 0.75rem 0.5rem;

        display: none;
        flex-direction: column;

        background-color: whiteColor;
    }

    .country-select-modal-content:focus-within  .country-select-radio-group
    {
        display: flex;
    }
</style>

<div class="country-select-modal-content">
    <div class="country-select-search-input-container">
        <div class="gray-search-icon size-150" />
        <input
            bind:value={ countrySearchTerm }
            class="country-select-search-input"
            placeholder={ getLocalizedTextBySlug( 'ad-search-country-label', $languageTagStore ) }
        />
    </div>

    <div class="country-select-radio-group">
        { #each filteredCountryArray as _country }
            <label class="radio-input-container">
                <input
                    type="radio"
                    value={ _country.code }
                    name="country-code"
                    bind:group={ countryCode }
                    on:change={ () => country = _country }
                />
                <img
                    class="country-select-country-radio-input-flag"
                    src="/image/flag/{ _country.code }.svg"
                    alt='flag'
                >
                <div class="font-size-90 font-weight-500 color-gray-300">
                    { getLocalizedText( _country.name, $languageTagStore ) }
                </div>
            </label>
        { /each }
    </div>
</div>
