<script>
    // -- IMPORTS

    import { getFileExtension, getRandomTuid } from 'senselogic-gist';
    import { SortableList } from '@jhubbardsf/svelte-sortablejs';
    import { encodeFileToBase64Url } from '$lib/base';
    import { writable } from 'svelte/store';
    import { getStorageImagePath } from '$src/lib/storage';

    // -- VARIABLES

    export let fileInputName;
    export let maxFilesLength;
    export let files = [];
    export let fileInput;
    export let retainedInputMap = {};
    export let retainedIndexInputMap = {};
    export let indexInputMap = {};
    export let deletedInputMap = {};
    export let fileCount = 0;
    export let fullWidth = false;
    let newFileArray = writable( [] );
    let initialFileArray = writable( [] );
    let deletedFileArray = writable( [] );
    let filesContainer;
    let acceptedType = 'image/*';
    $newFileArray = [];
    $initialFileArray = files.map( ( file, index ) => ( { src: file, type: acceptedType, index, fileType: 'initial' } ) ) ;
    $deletedFileArray = [];

    // -- FUNCTIONS

    async function handleInput(
        event
        )
    {
        let files = event.target.files;

        if ( !files )
        {
            return;
        }

        let promises
            = Array.from( files )
                .map(
                    async ( file ) =>
                    {
                        let dataUrl = await encodeFileToBase64Url( file );
                        let uniqueId = getRandomTuid();

                        return { src: dataUrl, type: file.type, name: file.name, uniqueId: uniqueId };
                    }
                    );

        let results = await Promise.all( promises );
        newFileArray.update(
            existingFiles =>
            [
                ...existingFiles,
                ...results.map(
                    ( result, index ) =>
                    (
                        {
                            ...result,
                            index: $initialFileArray.length + existingFiles.length + index,
                            fileType: 'new'
                        }
                    )
                    )
            ]
            );
    }

    // ~~

    function handleSort(
        event
        )
    {
        updateIndices( event.from );
    }

    // ~~

    function handleDelete(
        index,
        array
        )
    {
        array.update(
            items =>
            {
                let deletedFile = items.splice( index, 1 )[ 0 ];
                deletedFileArray.update( deletedItems => [ ...deletedItems, deletedFile.src ] );

                return items;
            }
            );

        setTimeout(
            () =>
            {
                    let arrayLength;
                    array.subscribe( ( value ) => arrayLength = value.length );

                    if ( arrayLength )
                    {
                        updateIndices( filesContainer.$$.ctx[ 1 ] );
                    }
            },
            0
            );
    }

    // ~~

    function updateIndices(
        container
        )
    {
        let newIndices
            = Array.from( container.children )
                .map(
                    ( child, newIndex ) =>
                    {
                        return (
                            {
                                oldIndex: parseInt( child.getAttribute( 'item-index' ) ),
                                newIndex: newIndex,
                                fileType: child.getAttribute( 'item-file-type' )
                            }
                            );
                    }
                    );

        initialFileArray.update(
            files =>
            (
                files.map(
                    file =>
                    {
                        let match = newIndices.find( item => item.oldIndex === file.index && item.fileType === 'initial' );

                        return match ? { ...file, index: match.newIndex } : file;
                    }
                    )
            )
            );

        newFileArray.update(
            files =>
            (
                files.map(
                    file =>
                    {
                        let match = newIndices.find( item => item.oldIndex === file.index && item.fileType === 'new' );

                        return match ? { ...file, index: match.newIndex } : file;
                    }
                    )
            )
            );
    }

    // ~~

    let handleClickEvent = () => {
        fileInput.click();
    };

    $: fileCount = $initialFileArray.length + $newFileArray.length;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    .file-input
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    :global( .file-input >div )
    {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .file-input-item
    {
        position: relative;

        min-height: 10rem;
        width: 10rem;

        cursor: grab;
    }

    .file-input-item.full-width
    {
        width: 100%;
    }

    .file-input-item img
    {
        height: 100%;
        width: 100%;
        border-radius: 1rem;

        object-fit: cover;
    }

    .file-input-remove-button
    {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: redColor;
    }

    .file-input-add-new-button
    {
        height: 10rem;
        width: 10rem;
        border: 2px solid blueColor900;
        border-radius: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: blueColor950;

        cursor: pointer;
    }

    .file-input-add-new-button.full-width
    {
        width: 100%;
    }
</style>

<div class="file-input">
    { #if $initialFileArray.length > 0 || $newFileArray.length > 0 }
        <SortableList
            class="file-input-list"
            bind:this={ filesContainer }
            onEnd={ handleSort }
        >
            { #each $initialFileArray as fileData, index }
                <div
                    class="file-input-item"
                    class:full-width={ fullWidth }
                    item-index="{ fileData.index }"
                    item-file-type="{ fileData.fileType }"
                    item-src="{ fileData.src }"
                >
                    { #if getFileExtension( fileData.src ) === 'pdf' }
                        <div class="file-icon-container">
                            <div class="green-files-icon size-350"/>
                        </div>
                    { :else }
                        <img src={ getStorageImagePath( fileData.src, 640 ) } alt="file"/>
                    { /if }
                    <input
                        type="hidden"
                        name={ fileInputName + '-retained' + index }
                        value={ fileData.src }
                        bind:this={ retainedInputMap[ fileInputName + '-retained' + index ] }
                    />
                    <input
                        type="hidden"
                        name={ fileInputName + '-retained-index' }
                        value={ fileData.index }
                        bind:this={ retainedIndexInputMap[ fileInputName + '-retained-index' + index] }
                        />
                    <button
                        class="file-input-remove-button"
                        on:click|preventDefault={ () => handleDelete( index, initialFileArray ) }
                    >
                        <div class="white-close-icon size-90" />
                    </button>
                </div>
            { /each }
            { #each $newFileArray as fileData, index ( fileData.uniqueId ) }
                <div
                    class="file-input-item"
                    class:full-width={ fullWidth }
                    item-index="{ fileData.index }"
                    item-file-type="{ fileData.fileType }"
                    item-src="{ fileData.src }"
                >
                    { #if getFileExtension( fileData.src ) === 'pdf' }
                        <div class="file-icon-container">
                            <div class="green-files-icon size-350"/>
                        </div>
                    { :else }
                        <img src={ getStorageImagePath( fileData.src, 640 ) } alt="file"/>
                    { /if }
                    <input
                        type="hidden" name={ fileInputName + '-index' + index }
                        value={ fileData.index }
                        bind:this={ indexInputMap[ fileInputName + '-index' + index ] }
                    />
                    <button
                        class="file-input-remove-button"
                        on:click|preventDefault={ () => handleDelete( index, newFileArray ) }
                    >
                        <div class="white-close-icon size-90" />
                    </button>
                </div>
            { /each }
        </SortableList>
    { /if }
    <input
        type="hidden"
        bind:value={ $deletedFileArray }
        name={ fileInputName + '-deleted' }
        bind:this={ deletedInputMap[ fileInputName + '-deleted' ] }
    />
    <input
        bind:this={ fileInput }
        name={ fileInputName }
        type="file"
        enctype="multipart/form-data"
        accept={ acceptedType }
        on:change={ handleInput }
        multiple
        style="display:none"
    />
    { #if ( $initialFileArray.length + $newFileArray.length ) < maxFilesLength }
        <button
            class="file-input-add-new-button"
            class:full-width={ fullWidth }
            on:click|preventDefault={ handleClickEvent }
        >
            <div class="blue-plus-circle-icon size-150"/>
        </button>
    { /if }
</div>
