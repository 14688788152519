<script>
    // -- IMPORTS

    import { getStorageImagePath } from "$src/lib/storage";
    import { profileSignedInStore } from "$src/lib/store/profileStore";

    // -- VARIABLES

    export let profile;
    export let size = 'small';
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .profile-image
    {
        box-sizing: border-box;
        height: 2rem;
        width: 2rem;
        aspect-ratio: 1;
        border: 2px solid grayColor950;
        border-radius: 2rem;

        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;

        background-color: greenColor900;

        text-transform: uppercase;
        color: greenColor300;
    }

    .profile-image.medium
    {
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;

        font-size: 1.5rem;
    }

    .profile-image.large
    {
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 4.5rem;

        font-size: 2.25rem;
    }
</style>

<img
    class='profile-image { size }'
    src={ getStorageImagePath( profile.imagePath, 640 ) }
    alt={ profile.firstName.charAt( 0 ) }
/>
