<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { updatePropertyParameters } from '$store/filterParameterByKeyMapStore.js';
    import { isOnboardingCompletedStore } from '$store/onboardingStore';
    import { authModalStore } from '$store/authModalStore';
    import ModalButton from '$component/modal/ModalButton.svelte';

    // -- VARIABLES

    export let goToNextStep;

    // -- FUNCTIONS

    function handleTermTypeSelected(
        termType
        )
    {
        updatePropertyParameters( { [ termType ]: true } );

        setTimeout(
            () =>
            {
                goToNextStep();
            },
            500
            );
    }

    // ~~

    function handleBecomeHost(
        )
    {
        isOnboardingCompletedStore.set( true );
        $authModalStore = 'sign-in';
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    .onboarding-term-type
    {
        padding: 0 1.5rem;

        +media( desktop )
        {
            padding: 2.5rem 1.5rem;
        }

        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
    }

    .onboarding-term-type::before
    {
        content: unset !important;
    }

    .onboarding-term-type-container
    {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 5rem;
        justify-content: center;
        align-items: center;

        +media( desktop )
        {
            gap: 3.125rem;
        }
    }

    .onboarding-term-type-content
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
    }

    .onboarding-term-type-items
    {
        width: 100%;

        display: flex;
        gap: 0.75rem;
        justify-content: center;

        +media( tablet )
        {
            max-width: 60vw;
        }

        +media( desktop )
        {
            max-width: 40vw;
        }
    }

    .onboarding-term-type-item
    {
        position: relative;

        height: 10rem;
        max-height: 50vw;
        width: 10.375rem;
        border: 2px solid grayColor800;
        border-radius: 1rem;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        background-color: grayColor950;

        text-align: unset;
    }

    // .onboarding-term-type-item-icon
    // {
    // }

    // .onboarding-term-type-item-icon::before
    // {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;

    //     display: block;

    //     content: '';
    //     background-size: cover !important;
    //     background-position-y: -2rem !important;

    //     height: 100%;
    //     width: 100%;
    //     border-radius: 1rem;

    //    //  box-shadow: 0px 8px 16px 0px rgba( 0, 0, 0, 0.08 );
    // }

    // .onboarding-term-type-item-icon.short-term::before
    // {
    //     // background: url( '/image/onboarding/onboarding_short_term.png' ) no-repeat center center;
    //     // background-color: #DDFCF3;
    // }

    // .onboarding-term-type-item-icon.long-term::before
    // {
    //     // background: url( '/image/onboarding/onboarding_long_term.png' ) no-repeat center center;
    //     // background-color: #E8F1FF;
    // }

    .onboarding-term-type-item-label
    {
    }

    // .onboarding-term-type-host
    // {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 1rem;
    //     align-items: center;
    // }

    // .onboarding-term-type-host >div
    // {
    //     width: 80%;

    //     text-align: center;
    // }

    // .onboarding-term-type-host >button
    // {
    //     display: flex;
    //     flex-direction: row;
    //     gap: 0.5rem;
    //     align-items: center;
    // }

    // .onboarding-term-type-ignore-step-button
    // {
    //     margin-bottom: 3rem;

    //     +media( desktop )
    //     {
    //         margin-bottom: unset;
    //     }
    // }

    .onboarding-term-type-button-container
    {
        margin-bottom: 2rem;
        width: 100%;

        +media( desktop )
        {
            margin-bottom: unset;
        }
    }
</style>

<div class="onboarding-term-type">
    <div class="onboarding-term-type-container">
        <div class="onboarding-term-type-content">
            <div class="font-size-125 font-weight-600 color-gray-300">
                { getLocalizedTextBySlug( 'onboarding-welcome-label' ) }
            </div>
            <div class="moien-logo-icon height-400 width-1200" />
            <div class="font-size-100 font-weight-500 color-gray-300">
                { getLocalizedTextBySlug( 'onboarding-message-label' ) }
            </div>
        </div>
        <div class="onboarding-term-type-items">
            <button
                class="onboarding-term-type-item"
                on:click={ () => ( handleTermTypeSelected( 'isAvailableForShortTerm' ) ) }
            >
                <div class="gray-backpack-icon size-200 short-term"/>
                <div class="font-size-100 font-weight-700 color-gray-100 onboarding-term-type-item-label">
                    { getLocalizedTextBySlug( 'term-type-short-term' ) }
                </div>
            </button>
            <button
                class="onboarding-term-type-item"
                on:click={ () => ( handleTermTypeSelected( 'isAvailableForLongTerm' ) ) }
            >
                <div class="gray-house-icon size-200 long-term"/>
                <div class="font-size-100 font-weight-700 color-gray-100 onboarding-term-type-item-label">
                    { getLocalizedTextBySlug( 'term-type-long-term' ) }
                </div>
            </button>
        </div>
        <!-- <div class="onboarding-term-type-host">
            <div class="font-size-100 font-weight-600 color-blue">
                { getLocalizedTextBySlug( 'onboarding-host-question-label' ) }
            </div>
            <button class="font-size-90 font-weight-600 color-green" on:click={ () => ( handleBecomeHost() ) }>
                <div class="green-right-arrow-icon size-150"/>
                { getLocalizedTextBySlug( 'onboarding-host-link-label' ) }
            </button>
        </div> -->
    </div>
    <div class="onboarding-term-type-button-container">
        <ModalButton
            variant="contained"
            on:click={ goToNextStep }
            text={ getLocalizedTextBySlug( 'onboarding-skip-label' ) }
        />
    </div>
</div>
