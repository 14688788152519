<script>
    // -- IMPORTS

    import { fetchData, hostUrl } from "$src/lib/base";
    import { onMount } from "svelte";
    import DocumentTypeInput from "../supporting-document/DocumentTypeInput.svelte";
    import { documentStatusByIdMapStore } from "$src/lib/store/documentStatusStore";
    import { documentTypeArrayStore, documentTypeByIdMapStore } from "$src/lib/store/documentTypeStore";
    import CountryOfIssueInput from "../supporting-document/CountryOfIssueInput.svelte";
    import FileInput from "../../backoffice/FileInput.svelte";
    import SupportingDocumentAccordion from "../supporting-document/SupportingDocumentAccordion.svelte";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import { getLocalizedTextBySlug } from "senselogic-gist";
    import ModalButton from "../../modal/ModalButton.svelte";
    import Error from "../../element/Error.svelte";
    import Loading from "../../element/Loading.svelte";

    // -- VARIABLES

    export let showDocumentList = true;
    let documentArray = [];
    let errorArray = [];
    let document = {};
    let fileArray = new Array();
    let fileInput;
    let retainedInputMap = {};
    let retainedIndexInputMap = {};
    let indexInputMap = {};
    let deletedInputMap = {};
    let isLoading = true;
    let isSubmitting = false;

    // -- FUNCTIONS

    function validateDocumentData(
        )
    {
        errorArray = [];

        if ( !document.typeId )
        {
            errorArray.push( 'supporting-documents-page-document-type-error' );
        }

        if ( !document.countryCode )
        {
            errorArray.push( 'supporting-documents-page-document-country-of-issue-error' );
        }

        if ( errorArray.length !== 0 )
        {
            return true
        }

        return false;
    }

    // ~~

    async function handleSubmit(
        )
    {
        let foundError = validateDocumentData();

        if ( foundError )
        {
            return false;
        }

        let formData = new FormData();
        formData.append( 'documentData', JSON.stringify( document ) );

        for ( let i = 0; i < fileInput.files.length; ++i )
        {
            formData.append( 'file', fileInput.files[ i ] );
        }

        for ( let [ name, element ] of Object.entries( retainedInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( retainedIndexInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( deletedInputMap ) )
        {
            if ( element )
            {
                formData.append( name, element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( indexInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        let newFileIndex = formData.getAll( 'file-path-index' );
        let initialFile = formData.getAll( 'file-path-retained' );
        let initialFileIndex = formData.getAll( 'file-path-retained-index' );
        let deletedFile = formData.getAll( 'file-path-deleted' );
        formData.append( 'newFileIndex', JSON.stringify( newFileIndex ) );
        formData.append( 'initialFile', JSON.stringify( initialFile ) );
        formData.append( 'initialFileIndex', JSON.stringify( initialFileIndex ) );
        formData.append( 'deletedFile', JSON.stringify( deletedFile ) );
        formData.append( 'type', 'add' );

        isSubmitting = true;

        let response
            = await fetch(
                hostUrl + '/api/document/new',
                {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                }
                );
        if ( !response.ok )
        {
            let result = await response.json();

            if ( result.error )
            {
                errorArray = [ ...errorArray, result.error ];
            }
        }
        else
        {
            let result = await response.json();

            documentArray = [ ...documentArray, result.document ];
        }

        isSubmitting = false;
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            let pageData = await fetchData(
                '/api/page/supporting-document',
                {
                    method: 'POST',
                    credentials: 'include'
                }
            );

            documentArray = pageData.documentArray;

            if ( $documentStatusByIdMapStore === null )
            {
                $documentStatusByIdMapStore = pageData.documentStatusMap;
            }

            if ( $documentTypeArrayStore === null )
            {
                $documentTypeArrayStore = pageData.documentTypeArray;
            }

            if ( $documentTypeByIdMapStore === null )
            {
                $documentTypeByIdMapStore = pageData.documentTypeMap;
            }

            isLoading = false;
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../../constant.styl";
    @import "../../../../mixin.styl";

    // -- CLASSES

    .supporting-document-list
    {
        width: 100%;

        display:flex;
        flex-direction: column;
    }
</style>

{ #if isLoading }
    <Loading />
{ :else }
    <form
        class="supporting-document-form"
        on:submit|preventDefault={ handleSubmit }
    >
        { #key errorArray }
            { #each errorArray as error }
                <Error error={ error } />
            { /each }
        { /key }
        <DocumentTypeInput
            documentTypeArray={ $documentTypeArrayStore }
            bind:documentTypeId={ document.typeId }
        />
        <CountryOfIssueInput bind:countryCode={ document.countryCode } />
        <div class="display-flex flex-direction-column gap-150 padding-vertical-150">
            <FileInput
                fullWidth={ true }
                fileInputName="file-path"
                acceptedType="image/*,application/pdf"
                maxFilesLength="1"
                bind:files={ fileArray }
                bind:fileInput
                bind:indexInputMap
                bind:deletedInputMap
                bind:retainedInputMap
                bind:retainedIndexInputMap
            />
            <ModalButton
                text={ getLocalizedTextBySlug( 'submit-label', $languageTagStore ) }
                isLoading={ isSubmitting }
            />
        </div>
    </form>
    { #if showDocumentList }
        <div class="supporting-document-list">
            { #each documentArray as document }
                <SupportingDocumentAccordion document={ document } bind:documentArray={ documentArray }/>
            { /each }
        </div>
    { /if }
{ /if }
