<script>
    // -- CONSTANTS

    export let optionArray;
    export let selected;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .radio-group
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .radio-card
    {
        border: 2px solid lightGrayBorderColor;
        border-radius: 1rem;
        padding: 1rem;

        display: flex;
        gap: 1rem;
        align-items: center;

        background-color: whiteColor;

        cursor: pointer;
        transition: .4s ease;
    }

    .radio-card-text-group
    {
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .radio-button
    {
        height: 1.125rem;
        width: 1.125rem;
        accent-color: greenColor;
    }

    .radio-card.checked
    {
        border-color: greenColor800;

        background-color: greenColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        color: greenColor100;
    }
</style>

<div
    class="radio-group"
    role="radiogroup"
>
    { #each optionArray as option }
        <label
            class="radio-card"
            class:checked={ selected === option.value }
        >
            { #if option.icon }
                <div class="{ option.icon }-icon size-280" />
            { /if }
            <div class="radio-card-text-group">
                <div class="font-size-90 font-weight-700 color-gray-100">
                    { option.label }
                </div>
                { #if option.description }
                    <div class="font-size-75 font-weight-500 color-gray-100">
                        { option.description }
                    </div>
                { /if }
            </div>
            <input
                class="radio-button"
                type="radio"
                value={ option.value }
                bind:group={ selected }
                name="payment-method"
            />
        </label>
    { /each }
</div>
