<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { navigate } from 'svelte-routing';
    import { Browser } from '@capacitor/browser';
    import haversine from 'haversine-distance';
    import { getLocalizedTextBySlug, getMap } from 'senselogic-gist';
    import { calculateCarbonEmission, fetchData, formatPrice } from '$lib/base';
    import { countNightsBetweenArrivalDateAndDepartureDate } from '$lib/booking';
    import { languageTagStore } from '$store/languageTagStore';
    import { defaultLocationStore } from '$store/locationStore';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { totalGuestCounterStore, bookedPropertyStore } from '$store/bookingStore';
    import Error from '$component/element/Error.svelte';
    import CheckoutPageHeader from '../component/page/checkout/CheckoutPageHeader.svelte';
    import CheckoutPageEmissionStep from '../component/page/checkout/CheckoutPageEmissionStep.svelte';
    import CheckoutPageCalculationStep from '../component/page/checkout/CheckoutPageCalculationStep.svelte';
    import CheckoutPagePaymentStep from '../component/page/checkout/CheckoutPagePaymentStep.svelte';
    import CheckoutPageActions from '../component/page/checkout/CheckoutPageActions.svelte';
    import CheckoutPageSuccessModal from '../component/page/checkout/CheckoutPageSuccessModal.svelte';

    // -- VARIABLES

    export let id;
    let isLoading = true;
    let bookingId = id;
    let checkoutStepArray =
        [
            'Emission Start',
            'Emission Calculation',
            'Payment'
        ];
    let activeStep = 0;
    let featureByTypeIdMap = getMap( $bookedPropertyStore.featureArray, 'typeId' );
    let hasInstantBooking = featureByTypeIdMap[ 'has-instant-booking' ]?.value === 'true';
    let price = $bookedPropertyStore.shortTermDailyPrice;
    let donation = 0;
    let compensation = 0;
    $: nightCount
        = countNightsBetweenArrivalDateAndDepartureDate(
            $filterParameterByKeyMapStore.bookingParameters.arrivalDate,
            $filterParameterByKeyMapStore.bookingParameters.departureDate
            );
    $: totalPrice = ( price * nightCount ) + donation + compensation;
    let cancellationPolicyId = null;
    let paymentMethodArray = [];
    let selectedPaymentMethod = '';
    let isSubmitting = false;
    let errorMessage = null;
    let carbonEmission = 0;
    let departureCity =
        {
            latitude: $defaultLocationStore.latitude,
            longitude: $defaultLocationStore.longitude
        };
    let vehicleTypeByIdMap =
        {
            bike: { id: 'bike', label: 'Bike¨fr:Vélo¨de:Fahrrad', grams: 0 },
            train: { id: 'train', label: 'Train¨de:ZUg', grams: 14 },
            bus: { id: 'bus', label: 'Bus', grams: 68 },
            car: { id: 'car', label: 'Car¨fr:Voiture¨de:Auto', grams: 104, fourPassangers: 42 },
            suv: { id: 'suv', label: 'SUV', grams: 158, fourPassangers: 55 },
            motorcycle: { id: 'motorcycle', label: 'Motorbike¨fr:Moto¨de:Motorrad', grams: 72 },
            plane: { id: 'plane', label: 'Plane¨fr:Avion¨de:Flugzeug', grams: 285 }
        };
    let selectedVehicleTypeId = 'train';
    let selectedCity =
        {
            id: '',
            code: '',
            countryCode: '',
            name: '',
            searchName: '',
            provinceName: '',
            countryName: '',
            latitude: $defaultLocationStore.latitude,
            longitude: $defaultLocationStore.longitude
        };
    let cityName = '';
    let distance;
    let isSuccessModalOpen = false;

    // ~~

    async function handleSubmitBooking(
        )
    {
        if ( !cityName )
        {
            return;
        }
        else
        {
            departureCity = selectedCity;
        }

        if ( activeStep === 0 )
        {
            compensation = Math.ceil( calculateCompensation( carbonEmission ) );
        }

        if ( activeStep < 2 )
        {
            activeStep++;
            return;
        }

        if ( checkoutStepArray[ activeStep ] === 'Success' )
        {
            navigate( '/' );
        }

        let data =
            {
                propertyUserId: $bookedPropertyStore.userId,
                price: price * nightCount,
                paymentMethodId: selectedPaymentMethod,
                rentalBooking:
                    {
                        id: bookingId,
                        propertyId: $bookedPropertyStore.id,
                        guestCount: $totalGuestCounterStore,
                        arrivalDate: $filterParameterByKeyMapStore.bookingParameters.arrivalDate,
                        departureDate: $filterParameterByKeyMapStore.bookingParameters.departureDate,
                        dayCount: nightCount,
                        donation: donation,
                        carbonCompensationDonation: compensation,
                        totalPrice: totalPrice,
                        cancellationPolicyId: cancellationPolicyId,
                        status: 'requested'
                    },
                browserInfo:
                    {
                        JavaEnabled: navigator.javaEnabled(),
                        Language: navigator.language,
                        ColorDepth: window.screen.colorDepth,
                        ScreenHeight: window.screen.height,
                        ScreenWidth: window.screen.width,
                        TimeZoneOffset: new Date().getTimezoneOffset(),
                        UserAgent: navigator.userAgent,
                        JavascriptEnabled: true
                    }
            };

        isSubmitting = true;
        errorMessage = '';

        let bookingData = await fetchData(
            '/api/rental-booking/add',
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify( { data: data, type: 'addRentalBooking' } )
            }
            );

        if ( bookingData.secureModeRedirectUrl )
        {
            Browser.open( { url: bookingData.secureModeRedirectUrl } );
            isSubmitting = false;

            navigate( '/' );
        }

        if ( bookingData.error )
        {
            activeStep = 0;
            errorMessage = bookingData.error;
        }
        else
        {
            isSuccessModalOpen = true;
        }

        isSubmitting = false;
    }

    // ~~

    async function populatePaymentMethodArrayFromPaymentMethodMap(
        paymentMethodMap
        )
    {
        for ( let card of paymentMethodMap.cardArray )
        {
            if ( card.Active )
            {
                let expirationDate = card.ExpirationDate.substring( 0, 2 ) + '/' + card.ExpirationDate.substring( 2, 4 );
                paymentMethodArray.push(
                    {
                        label: `${ card.CardProvider } ${ card.Alias }`,
                        description:
                            (
                                getLocalizedTextBySlug( 'expiration-label', $languageTagStore )
                                + ': '
                                + expirationDate
                            ),
                        icon: card.CardProvider.toLowerCase(),
                        value: card.Id
                    }
                    );
            }
        }

        for ( let wallet of paymentMethodMap.walletArray )
        {
            paymentMethodArray.push(
                {
                    label: wallet.Description,
                    description:
                        (
                            getLocalizedTextBySlug( 'balance-label', $languageTagStore )
                            + ': '
                            + formatPrice( wallet.Balance.Amount / 100, $languageTagStore )
                        ) ,
                    value: wallet.Id
                }
                );
        }

        for( let bankAccount of paymentMethodMap.bankAccountArray )
        {
            paymentMethodArray.push(
                {
                    label: bankAccount.OwnerName,
                    description: `${ bankAccount.IBAN } · ${ bankAccount.BIC }`,
                    value: bankAccount.Id
                }
                );
        }
    }

    // ~~

    function calculateCompensation(
        carbonEmission
        )
    {
        const tonne = 1000;
        const carbonCreditPrice = 72;

        return ( carbonEmission / tonne ) * carbonCreditPrice;
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            let data
                = await fetchData(
                    '/api/page/checkout',
                    {
                        method: 'POST',
                        credentials: 'include',
                        body: JSON.stringify( { propertyId: $bookedPropertyStore.id } )
                    }
                    );

            if ( data.cancellationPolicy )
            {
                cancellationPolicyId = data.cancellationPolicy.value;
            }

            populatePaymentMethodArrayFromPaymentMethodMap( data.profilePaymentMethodMap );
            isLoading = false;
        }
        );

    // ~~

    $:
    {
        let departure = { latitude: departureCity.latitude, longitude: departureCity.longitude };
        let destination = { latitude: $bookedPropertyStore.latitude, longitude: $bookedPropertyStore.longitude };
        carbonEmission
            = calculateCarbonEmission(
                departure,
                destination,
                vehicleTypeByIdMap[ selectedVehicleTypeId ],
                $totalGuestCounterStore
                );
    };

    // ~~

    $: donation = Math.ceil( price * nightCount * 0.02 );

    // ~~

    $:
    {
        distance =
            (
                haversine(
                    { latitude: departureCity.latitude, longitude: departureCity.longitude },
                    { latitude: $bookedPropertyStore.latitude, longitude: $bookedPropertyStore.longitude }
                    )
                / 1000
            ).toFixed( 2 );
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .checkout-page
    {
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .checkout-page.success
    {
        background-color: blueColor950;
    }

    .checkout-page.wait
    {
        cursor: wait !important;
    }

    .checkout-page-container
    {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        -ms-overflow-style: none;
        scrollbar-width: none;
        max-height: calc( 100dvh - 10rem );
        max-width: 76.875rem;

        +media( desktop )
        {
            max-height: unset;
        }
    }

    .checkout-page::-webkit-scrollbar
    {
        display: none
    }
</style>

<div class="checkout-page" class:success={ activeStep === 3 } class:wait={ isSubmitting } >
    <div class="checkout-page-container">
        <CheckoutPageHeader bind:activeStep={ activeStep } />
        { #key errorMessage }
            <Error error={ errorMessage } />
        { /key }
        { #if checkoutStepArray[ activeStep ] === 'Emission Start' }
            <CheckoutPageEmissionStep
                vehicleTypeByIdMap={ vehicleTypeByIdMap }
                handleSubmit={ handleSubmitBooking }
                bind:cityName={ cityName }
                bind:selectedCity={ selectedCity }
                bind:selectedVehicleTypeId={ selectedVehicleTypeId }
            />
        { :else if checkoutStepArray[ activeStep ] === 'Emission Calculation' }
            <CheckoutPageCalculationStep
                selectedCity={ selectedCity }
                distance={ distance }
                selectedVehicleType={ vehicleTypeByIdMap[ selectedVehicleTypeId ] }
                carbonEmission={ carbonEmission }
                handleSubmit={ handleSubmitBooking }
                bind:compensation={ compensation }
            />
        { :else if checkoutStepArray[ activeStep ] === "Payment" }
            <CheckoutPagePaymentStep
                price={ price }
                nightCount={ nightCount }
                compensation={ compensation }
                hasInstantBooking={ hasInstantBooking }
                populatePaymentMethodArrayFromPaymentMethodMap={ populatePaymentMethodArrayFromPaymentMethodMap }
                handleSubmit={ handleSubmitBooking }
                isSubmitting={ isSubmitting }
                bind:donation={ donation }
                bind:totalPrice={ totalPrice }
                bind:paymentMethodArray={ paymentMethodArray }
                bind:selectedPaymentMethod={ selectedPaymentMethod }
                bind:isLoading={ isLoading }
            />
        { /if }
    </div>
    <CheckoutPageActions
        isSubmitting={ isSubmitting }
        checkoutStepArray={ checkoutStepArray }
        activeStep={ activeStep }
        totalPrice={ totalPrice }
        handleSubmitBooking={ handleSubmitBooking }
    />
</div>
<CheckoutPageSuccessModal bind:isOpen={ isSuccessModalOpen } bookingId={ bookingId } />
