<script>
    // -- IMPORTS

    import { Route } from "svelte-routing";
    import Menu from "$component/layout/Menu.svelte";

    // -- VARIABLES

    export let path;
    export let component;
    export let componentParams = {};
</script>

<Route path={ path } let:params>
    <svelte:component this={ component } { ...params } { ...componentParams } />
    <Menu/>
</Route>
