<script>
    // -- IMPORTS

    import { onDestroy } from 'svelte';
    import BigPicture from 'bigpicture';
    import { getLocalizedText } from 'senselogic-gist';
    import { languageTagStore } from '$store/languageTagStore';
    import { getRatingText } from '$lib/base';
    import { getStorageImagePath } from '$lib/storage';
    import { handlePropertyFavorite, checkPropertyFavorite } from '$lib/favorite';
    import { profileSignedInStore } from '$store/profileStore';
    import GoBack from '$component/layout/GoBack.svelte';
    import Carousel from '$component/element/Carousel.svelte';
    import StoryViewer from '$component/element/StoryViewer.svelte';

    // -- CONSTANTS

    const windowMatchesMaxWidth56em = window.matchMedia( '(max-width: 56em)' );

    // -- VARIABLES

    export let propertyId;
    export let title;
    export let averageRating;
    export let cityName;
    export let countryName;
    export let imagePathArray;

    let isFavorite = checkPropertyFavorite( $profileSignedInStore, propertyId );
    let isScreenAtPhoneSize = windowMatchesMaxWidth56em.matches;

    // -- FUNCTIONS

    function handleOpenGallery(
        event
        )
    {
        let targetElement = event.target;

        let index = parseInt( targetElement.getAttribute( 'data-index' ), 10 );

        let imageArray = document.querySelectorAll( '.property-heading-image-list img.gallery-image' );

        let imageUrlArray = Array.from( imageArray ).map( img => ( { src: img.src } ) );

        imageUrlArray = imageUrlArray.slice( 1, -1 );

        BigPicture(
            {
                el: event.target,
                gallery: imageUrlArray,
                animEl: event.target,
                pos: index
            }
            );
    }

    // ~~

    function handleResizeEvent(
        )
    {
        isScreenAtPhoneSize = windowMatchesMaxWidth56em.matches;
    }

    // -- STATEMENTS

    window.addEventListener( "resize", handleResizeEvent );

    // ~~

    onDestroy(
        () =>
        {
            window.removeEventListener( "resize", handleResizeEvent );
        }
    );

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .property-heading
    {
        position: relative;
    }

    .property-heading-actions
    {
        z-index: 2;
        position: absolute;
        top: 1rem;
        right: 1rem;

        display: flex;
        flex-direction: row;
        gap: 1rem;

        +media( desktop )
        {
            right: 0;
        }
    }

    .property-heading-city-average-rating,
    .property-heading-city-favorite
    {
        height: 2rem;
        width: max-content;
        border-radius: 0.75rem;
        padding: 0.75rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( desktop )
        {
            height: 2.5rem;
        }
    }

    .property-heading-go-back
    {
        z-index: 2;
        position: absolute;
        top: 1rem;
        left: 1rem;

        +media( desktop )
        {
            display: none;
        }
    }

    .property-heading-image-list-carousel-container
    {
        position: relative;
    }

    .property-heading-image-list-grid
    {
        +media( desktop )
        {
            width: calc( 100% - 3rem );

            display: grid;
            grid-template-columns: 30% 20% 30% 20%;
            gap: 1rem;
        }
    }

    .property-heading-image-list-grid .property-heading-image-list-carousel-container img
    {
        height: 20rem;
        width: 100%;

        object-fit: cover;
        object-position: center;

        +media( tablet )
        {
            height: 30rem;
        }

        +media( desktop )
        {
            border-radius: 0.75rem;
        }
    }

    .property-heading-image-list-grid-column
    {
        max-height: 30rem;
    }

    .property-heading-image-list-grid-column img,
    .property-heading-image-list-grid-column a
    {
        +media( desktop )
        {
            height: 100%;
            width: 100%;
            border-radius: 0.75rem;

            display: block;
            object-fit: cover;
        }
    }

    .property-heading-image-list-grid-column.single a
    {
        display: none;

        +media( desktop )
        {
            display: block;
        }
    }

    .property-heading-image-list-grid-column.doble
    {
        display: none;

        +media( desktop )
        {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    .property-heading-image-list-grid-column.doble a
    {
        +media( desktop )
        {
            max-height: 14.5rem;
        }
    }

    .property-heading-content
    {
        padding: 1.5rem 1rem;

        display: flex;
        flex-direction: column;
    }

    .property-heading-content.mobile
    {
        padding: 1.5rem 1rem 0 1rem;

        display: flex;

        +media( desktop )
        {
            display: none;
        }
    }

    .property-heading-content.large
    {
        display: none;

        +media( desktop )
        {
            border-bottom: unset;
            padding: unset;
            padding-bottom: 1.5rem;

            display: flex;
        }
    }

    .property-heading-image-list-carousel-gradient-overlay
    {
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        height: 100%;

        background: linear-gradient( 180deg, rgba( 0, 0, 0, 0.40 ) 0%, rgba( 0, 0, 0, 0 ) 100% );

        +media( desktop )
        {
            background: unset;
        }
    }
</style>

<div class="property-heading">
    <div class="property-heading-content large">
        <div class="font-size-150 font-weight-600 color-black property-heading-content-title">
            { getLocalizedText( title, $languageTagStore ) }
        </div>
        <div class="font-size-90 font-weight-500 color-gray property-heading-content-city-country">
            { #if cityName && countryName }
                { getLocalizedText( cityName, $languageTagStore ) } &#x2022; { getLocalizedText( countryName, $languageTagStore ) }
            { :else if cityName }
                { getLocalizedText( cityName, $languageTagStore ) }
            { :else if countryName }
                { getLocalizedText( countryName, $languageTagStore ) }
            { /if }
        </div>
    </div>
    <div class="property-heading-go-back">
        <GoBack/>
    </div>
    <div class="property-heading-actions">
        { #if averageRating }
            <div class="font-size-75 font-weight-700 color-green property-heading-city-average-rating">
                { getRatingText( averageRating ) }
            </div>
        { /if }
        { #if $profileSignedInStore }
            <button
                class="property-heading-city-favorite"
                on:click=
                {
                    async () =>
                    {
                        isFavorite = await handlePropertyFavorite( $profileSignedInStore, propertyId, isFavorite );
                    }
                }
            >
                <span class="{ isFavorite ? 'red' : 'gray' }-favorite-icon size-150">
                </span>
            </button>
        { /if }
    </div>

    <div class="property-heading-image-list">
        <div class="property-heading-image-list-grid">
            <div class="property-heading-image-list-grid-column">
                <Carousel totalItemsLength={ imagePathArray.length } autoplay={ 4000 }>
                    { #each imagePathArray as imagePath, imagePathArrayIndex }
                        <div class="property-heading-image-list-carousel-container">
                            <img
                                class="gallery-image"
                                src={ getStorageImagePath( imagePath, 1280 ) }
                                alt=""
                                data-index="{ imagePathArrayIndex }"
                            />
                            { #if !isScreenAtPhoneSize }
                                <a
                                    class="property-heading-image-list-carousel-gradient-overlay"
                                    href={ imagePath }
                                    data-index="{ imagePathArrayIndex }"
                                    on:click|preventDefault={ handleOpenGallery }
                                >
                                    <div></div>
                                </a>
                            { /if }
                        </div>
                    { /each }
                </Carousel>
                { #if isScreenAtPhoneSize }
                    <StoryViewer storyTitle={ getLocalizedText( title, $languageTagStore ) } imageArray={ imagePathArray }/>
                { /if }
            </div>
            { #each imagePathArray as imagePath, imagePathArrayIndex }
                 {#if imagePathArrayIndex < 5 }
                    { #if imagePathArrayIndex % 3 === 0 }
                        <div class="property-heading-image-list-grid-column doble">
                            <a href={ imagePath } on:click|preventDefault={ handleOpenGallery }>
                                <img
                                    loading="lazy"
                                    data-bp={ getStorageImagePath( imagePath, 1280 ) }
                                    data-index="{ imagePathArrayIndex }"
                                    src={ getStorageImagePath( imagePath, 1280 ) }
                                    alt=""
                                />
                            </a>
                            <a href={ imagePathArray[ imagePathArrayIndex + 1 ] } on:click|preventDefault={ handleOpenGallery }>
                                <img
                                    loading="lazy"
                                    data-bp={ getStorageImagePath( imagePathArray[ imagePathArrayIndex + 1 ], 1280 ) }
                                    data-index="{ imagePathArrayIndex }"
                                    src={ getStorageImagePath( imagePathArray[ imagePathArrayIndex + 1 ], 1280 ) }
                                    alt=""
                                />
                            </a>
                        </div>
                    { :else if imagePathArrayIndex % 3 === 2 }
                        <div class="property-heading-image-list-grid-column single">
                            <a href={ imagePath } on:click|preventDefault={ handleOpenGallery }>
                                <img
                                    loading="lazy"
                                    data-bp={ getStorageImagePath( imagePath, 1280 ) }
                                    data-index="{ imagePathArrayIndex }"
                                    src={ getStorageImagePath( imagePath, 1280 ) }
                                    alt=""
                                />
                            </a>
                        </div>
                    { /if }
                { /if }
            { /each }
        </div>
    </div>
    <div class="property-heading-content mobile">
        <div class="font-size-150 font-weight-600 color-black property-heading-content-title">
            { getLocalizedText( title, $languageTagStore ) }
        </div>
        <div class="font-size-90 font-weight-500 color-gray property-heading-content-city-country">
            { #if cityName && countryName }
                { getLocalizedText( cityName, $languageTagStore ) } &#x2022; { getLocalizedText( countryName, $languageTagStore ) }
            { :else if cityName }
                { getLocalizedText( cityName, $languageTagStore ) }
            { :else if countryName }
                { getLocalizedText( countryName, $languageTagStore ) }
            { /if }
        </div>
    </div>
</div>
