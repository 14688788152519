<script>
    // -- IMPORTS

    import { updatePropertyParameters } from '$store/filterParameterByKeyMapStore.js';
    import FilterPropertyType from '$component/filter/FilterPropertyType.svelte';
    import OnboardingAction from '$component/onboarding/OnboardingAction.svelte';

    // -- VARIABLES

    export let goToNextStep;

    function handlePropertyTypeIdSelected(
        event
        )
    {
        updatePropertyParameters( { 'typeId': event.detail } );
    }
</script>

<style lang="stylus" >
    // -- CLASSES

    .onboarding-property-type::before
    {
        background: url( '/image/onboarding/onboarding_property_type.png' ) no-repeat center center;
    }
</style>

<div class="onboarding-property-type">
    <div class="onboarding-property-type-content">
        <FilterPropertyType on:propertyTypeIdSelected={ handlePropertyTypeIdSelected } />
    </div>
    <OnboardingAction goToNextStep={ goToNextStep } />
</div>
