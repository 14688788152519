<script>
    // -- IMPORTS

    import { documentStatusByIdMapStore } from "$src/lib/store/documentStatusStore";
    import { documentTypeByIdMapStore } from "$src/lib/store/documentTypeStore";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import { getLocalizedText, getLocalizedTextBySlug } from "senselogic-gist";
    import { slide } from "svelte/transition";
    import FileInput from '$src/lib/component/backoffice/FileInput.svelte';
    import ModalButton from "../../modal/ModalButton.svelte";
    import { getStorageImagePath } from "$src/lib/storage";
    import { fetchData } from "$src/lib/base";

    // -- VARIABLES

    export let document;
    export let documentArray = [];
    let fileArray = [ document.filePath ];
    let fileInput;
    let retainedInputMap = {};
    let retainedIndexInputMap = {};
    let indexInputMap = {};
    let deletedInputMap = {};
    let form;
    let isEditing = false;
    let isDeleting = false;
    let isOpen = false;

    // -- FUNCTIONS

    async function handleUpdateDocument(
        )
    {
        let formData = new FormData();

        for ( let i = 0; i < fileInput.files.length; ++i )
        {
            formData.append( 'file', fileInput.files[ i ] );
        }

        for ( let [ name, element ] of Object.entries( retainedInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( retainedIndexInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( deletedInputMap ) )
        {
            if ( element )
            {
                formData.append( name, element.value );
            }
        }

        for ( let [ name, element ] of Object.entries( indexInputMap ) )
        {
            if ( element )
            {
                formData.append( name.substring( 0, name.length - 1 ), element.value );
            }
        }

        let newFileIndex = formData.getAll( 'file-path-index' );
        let initialFile = formData.getAll( 'file-path-retained' );
        let initialFileIndex = formData.getAll( 'file-path-retained-index' );
        let deletedFile = formData.getAll( 'file-path-deleted' );

        formData.append( 'newFileIndex', JSON.stringify( newFileIndex ) );
        formData.append( 'initialFile', JSON.stringify( initialFile ) );
        formData.append( 'initialFileIndex', JSON.stringify( initialFileIndex ) );
        formData.append( 'deletedFile', JSON.stringify( deletedFile ) );

        isEditing = true;

        let response
            = await fetchData(
                '/api/document/update-image/'+ document.id,
                {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                }
                );

        isEditing = false;
        isOpen = false;
    }

    // ~~

    async function handleDeleteDocument(
        )
    {
        isDeleting = true;

        let result
            = await fetchData(
                '/api/document/delete/'+ document.id,
                {
                    method: 'POST',
                    credentials: 'include'
                }
                );

        if ( result )
        {
            documentArray = documentArray.filter( _document => _document.id !== document.id );
        }

        isDeleting = false;
        isOpen = false;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../../constant.styl"

    // -- CLASSES

    .supporting-document-accordion
    {
        width: 100%;
        border-bottom: 1px solid lightGrayBorderColor
        padding: 0.75rem 0;

        display: flex;
        gap: 0.75rem;
        align-items: flex-start;

        text-align: unset;
    }

    .supporting-document-accordion.is-accordion-open
    {
        border-bottom: none;
    }

    .supporting-document-accordion-text-group
    {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 0.375rem;
    }

    .icon
    {
        transition: transform 0.2s ease-in;
    }

    .is-open
    {
        transform: rotate( 90deg );
    }

    .document-container
    {
        border-bottom: 1px solid lightGrayBorderColor;
        padding: 0.75rem 0;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .document-image
    {
        width: fit-content;
        max-width: 100%;
        border-radius: 0.75rem;
    }
</style>

<button
    class="supporting-document-accordion"
    class:is-accordion-open={ isOpen }
    type="button"
    on:click={ () => isOpen = !isOpen }
>
    { #if document.validationStatusId === 'declined' }
        <div class="declined-icon size-150" />
    { :else if document.validationStatusId === 'pending' }
        <div class="pending-icon size-150" />
    { :else }
        <div class="accepted-icon size-150" />
    { /if }
    <div class="supporting-document-accordion-text-group">
        <div class="font-size-90 font-weight-500 color-gray-300">
            {  getLocalizedText( $documentTypeByIdMapStore[ document.typeId ].name, $languageTagStore ) }
        </div>
        <div class="font-size-75 font-weight-500 color-gray">
            { getLocalizedText( $documentStatusByIdMapStore[ document.validationStatusId ].name, $languageTagStore ) }
        </div>
    </div>
    <div class="gray-right-caret-icon size-150 icon" class:is-open={ isOpen }/>
</button>
{ #if isOpen }
    <form
        class="document-container"
        bind:this={ form }
        on:submit|preventDefault={ handleUpdateDocument }
        transition:slide
    >
        { #if document.validationStatusId === 'accepted' }
            <img class="document-image" src={ getStorageImagePath( document.filePath, 512 ) } alt="document"/>
        { :else }
            <FileInput
                maxFilesLength="1"
                fileInputName="file-path"
                acceptedType="image/*,application/pdf"
                bind:files={ fileArray }
                bind:fileInput={ fileInput }
                bind:retainedIndexInputMap={ retainedIndexInputMap }
                bind:retainedInputMap={ retainedInputMap }
                bind:indexInputMap={ indexInputMap }
                bind:deletedInputMap={ deletedInputMap }
            />
        { /if }
        <div class="display-flex gap-50">
            <ModalButton
                type="button"
                variant="light"
                text={ getLocalizedTextBySlug( 'delete-label', $languageTagStore ) }
                fullWidth={ false }
                isLoading={ isDeleting }
                on:click={ handleDeleteDocument }
            />
            { #if document.validationStatusId !== 'accepted' }
                <ModalButton
                    variant="contained"
                    text={ getLocalizedTextBySlug( 'edit-label', $languageTagStore ) }
                    fullWidth={ false }
                    isLoading={ isEditing }
                />
            { /if }
        </div>
    </form>
{ /if }
