<script>
    // -- VARIABLES

    export let isSmall = false;
</script>

<style lang="stylus">
    // -- CLASSES

    @keyframes spin
    {
        from
        {
            transform:rotate( 0deg );
        }
        to
        {
            transform:rotate( 360deg );
        }
    }

    .outter
    {
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loading
    {
        height: 4rem;
        width: 4rem;

        animation-name: spin;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .is-small
    {
        height: 2rem;
        width: 2rem;
    }

    .loading > img
    {
        height: 100%;
        width: 100%;
    }
</style>

<div class="outter">
    <div class="loading" class:is-small={ isSmall }>
        <img src="/image/icon/loading.svg" alt="loading">
    </div>
</div>
