<script>
    // -- IMPORTS

    import { onMount } from "svelte";
    import ProfileContainer from "../component/page/profile/ProfileContainer.svelte";
    import { fetchData } from "../base";
    import Loading from "../component/element/Loading.svelte";
    import BadgeSection from "../component/page/profile/BadgeSection.svelte";
    import UserReviewSection from "../component/page/profile/UserReviewSection.svelte";
    import RentalFileSection from "../component/page/profile/RentalFileSection.svelte";
    import DocumentSection from "../component/page/profile/DocumentSection.svelte";
    import PropertySection from "../component/page/profile/PropertySection.svelte";

    // -- VARIABLES

    export let id;

    let profile;
    let propertyArray = [];
    let documentArray = [];
    let userReviewArray = [];
    let userReviewProfileByUserIdMap = {};
    let hasCompleteRentalFile = false;
    let isTenant = false;
    let isOwnProfile = false;
    let rentalFile;
    let isLoading = true;

    // -- STATEMENTS

    onMount(
        async () =>
        {
            let result = await fetchData( '/api/page/profile/' + id, { method: 'POST', credentials: 'include' } );
            profile = result.profile;
            propertyArray = result.propertyArray;
            documentArray = result.documentArray;
            userReviewArray = result.userReviewArray;
            userReviewProfileByUserIdMap = result.userReviewProfileByUserIdMap;
            hasCompleteRentalFile = result.hasCompleteRentalFile;
            isTenant = result.isTenant;
            rentalFile = result.rentalFile;
            isOwnProfile = result.isOwnProfile;

            isLoading = false;
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../constant.styl";
    @import "../../mixin.styl";

    // -- CLASSES

    .profile-page
    {
        position: relative;

        margin-bottom: 4rem;
        padding: 0rem 1rem;
        padding-bottom: 6.75rem;

        display: flex;
        flex-direction: column;
        align-items: center;

        +media( desktop )
        {
            margin-bottom: unset;
        }
    }

    .profile-page-content-container
    {
        width: 100%;
        padding-top: 2rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        +media( desktop )
        {
            max-width: 76.875rem;
        }
    }
</style>

<div class="profile-page">
    { #if isLoading }
        <Loading />
    { :else }
        <div class="profile-page-content-container">
            <ProfileContainer profile={ profile } />
            <BadgeSection
                profile={ profile }
                userReviewArray={ userReviewArray }
                hasCompleteRentalFile={ hasCompleteRentalFile }
            />
            { #if propertyArray.length }
                <PropertySection propertyArray={ propertyArray } />
            { /if }
            { #if userReviewArray.length > 0 }
                <UserReviewSection
                    userReviewArray={ userReviewArray }
                    userReviewProfileByUserIdMap={ userReviewProfileByUserIdMap }
                />
            { /if }
            { #if isTenant || isOwnProfile }
                { #if rentalFile }
                    <RentalFileSection rentalFile={ rentalFile } />
                { /if }
                { #if documentArray.length }
                    <DocumentSection documentArray={ documentArray } />
                { /if }
            { /if }
        </div>
    { /if }
</div>
