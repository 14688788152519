<script>
    // -- IMPORTS

    import { getLocalizedText, getLocalizedTextBySlug, getMap } from 'senselogic-gist';
    import { profileSignedInStore } from '$store/profileStore';
    import { languageArrayStore } from '$store/languageArrayStore';
    import { languageTagStore } from '$store/languageTagStore';
    import PersonalDetailInput from './PersonalDetailInput.svelte';
    import LabelledSelect from '../../element/LabelledSelect.svelte';
    import { currencyArrayStore } from '$src/lib/store/currencyArrayStore';
    import { onMount } from 'svelte';
    import { fetchData } from '$src/lib/base';
    import Loading from '../../element/Loading.svelte';

    // -- VARIABLES

    let isLoading = true;
    let languageByTagMap = getMap( $languageArrayStore, 'tag' );

    // -- STATEMENTS

    onMount(
        async () =>
            {
                if ( $currencyArrayStore === null )
                {
                    let result = await fetchData( '/api/currency', { method: 'POST' } );

                    $currencyArrayStore = result.currencyArray;
                }

                isLoading = false;
            }
        )
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .account-settings-container
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
    }

    :global( .account-settings-container .dashboard-accordion ),
    :global( .account-settings-container .dashboard-input )
    {
        border: 0;
    }
</style>

<div class="account-settings-container">
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'ad-search-language-label', $languageTagStore ) }
        initialValue={ getLocalizedText( languageByTagMap[ $profileSignedInStore.currencyCode ]?.name ?? '', $languageTagStore ) }
        editable={ true }
    >
        <LabelledSelect
            name="language-tag"
            label={ getLocalizedTextBySlug( 'ad-search-language-label', $languageTagStore ) }
            value={ $profileSignedInStore.languageTag }
            variant="contained"
        >
            { #each $languageArrayStore as language }
                <option value={ language.tag }>
                    { getLocalizedText( language.name, $languageTagStore ) }
                </option>
            { /each }
        </LabelledSelect>
    </PersonalDetailInput>
    <PersonalDetailInput
        label={ getLocalizedTextBySlug( 'ad-search-currency-label', $languageTagStore ) }
        initialValue={ $profileSignedInStore.currencyCode ?? '' }
        editable={ true }
    >
        { #if isLoading }
            <Loading />
        { :else }
            <LabelledSelect
                name="currency-code"
                label={ getLocalizedTextBySlug( 'ad-search-language-label', $languageTagStore ) }
                value={ $profileSignedInStore.currencyCode ?? '' }
                variant="contained"
            >
                { #each $currencyArrayStore as currency }
                    <option value={ currency.code }>
                        { getLocalizedText( currency.singularName, $languageTagStore ) }
                    </option>
                { /each }
            </LabelledSelect>
        { /if }
    </PersonalDetailInput>
</div>
