<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { fetchData } from '$lib/base';
    import { cityArrayStore } from '$store/cityArrayStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { languageTagStore } from '$store/languageTagStore';

    // -- VARIABLES

    let cityId = $profileSignedInStore.cityId;
    let city = {};
    let citySearchTerm = $profileSignedInStore.cityName;
    let cityArray = $cityArrayStore;
    let timer = null;
    let form;
    let isQueryingDatabase = false;

    // -- FUNCTIONS

    async function handleKeyUp(
        )
    {
        clearTimeout( timer );
        timer = setTimeout
            (
                () =>
                {
                    if ( form )
                    {
                        form.requestSubmit();
                    }
                },
                1000
            );
    }

    // ~~

    async function handleSubmit(
        )
    {
        if ( isQueryingDatabase )
        {
            return false;
        }

        isQueryingDatabase = true;
        try
        {
            let cityData
                = await fetchData(
                    '/api/city/get-by-seach-name',
                    {
                        method: 'POST',
                        body:
                            JSON.stringify
                                (
                                {
                                    type: 'searchBySearchName',
                                    searchName: citySearchTerm,
                                    countryCode: $profileSignedInStore.countryCode
                                }
                                ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

            cityArray = cityData.cityArray;
        }
        catch ( error )
        {
            console.error( 'Error :' + error );
        }
        finally
        {
            isQueryingDatabase = false;
        }
    }

    // ~~

    function handleCityChange(
        )
    {
        if ( city )
        {
            citySearchTerm = city.name;
        }
    }

    // -- STATEMENTS

    $:
    {
        city;
        handleCityChange();
    }

    // ~~

    onMount(
        async () =>
        {
            if ( $profileSignedInStore.cityCode )
            {
                let cityData = await fetchData(
                    '/api/city/get-by-id',
                    {
                        method: 'POST',
                        body: JSON.stringify( { type: 'searchById', cityId: $profileSignedInStore.cityCode} ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );

                    if ( cityData.city )
                    {
                        city = cityData.city;
                    }
            }
        }
    );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl'
    @import '../../../../mixin.styl'

    // -- CLASSES

    .city-select-modal-content
    {
        position: relative;

        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .city-select-search-input-container
    {
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 1rem 0.75rem;

        display: flex;
        gap: 0.5rem;
        align-items: center;

        background: grayColor950;

        transition: all 200ms ease-in-out;
        &:focus-within
        {
            border-color: greenColor900;

            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }

    .city-select-radio-group
    {
        overflow-y: auto;
    }

    .city-select-search-input
    {
        outline: none;
        border: none;
        border-radius: unset;
        padding: unset;

        background-color: transparent;

        font-size: 0.9rem;
        color: grayColor100;
    }

    .city-select-radio-group
    {
        -ms-overflow-style: none;
        position: absolute;
        top: 4rem;

        overflow-y: auto;
        scrollbar-width: none;
        height: 8rem;
        width: 100%;
        width: 100%;
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 0.75rem 0.5rem;

        display: none;
        flex-direction: column;

        background-color: whiteColor;
    }

    .city-select-modal-content:focus-within > .city-select-radio-group
    {
        display: flex;
    }
</style>

<div class="city-select-modal-content">
    <form
        class="city-select-search-input-container"
        on:submit|preventDefault={ handleSubmit }
        bind:this={ form }
    >
        <div class="gray-search-icon size-150" />
        <input
            bind:value={ citySearchTerm }
            on:keyup={ handleKeyUp }
            class="city-select-search-input"
            placeholder={ getLocalizedTextBySlug( 'ad-search-city-label', $languageTagStore ) }
            name="search-term"
        />
    </form>
    <div class="city-select-radio-group">
        { #if isQueryingDatabase }
            <div class="font-size-90 font-weight-500 color-gray-300">
               { getLocalizedTextBySlug( 'location-searching-label', $languageTagStore ) }
            </div>
        { :else }
            <input name="city-name" hidden bind:value={ city.name } />
            <input name="country-code" hidden bind:value={ city.countryCode } />
            { #each cityArray as _city ( _city.id ) }
                <label class="radio-input-container">
                    <input
                        type="radio"
                        value={ _city.id }
                        name="city-code"
                        bind:group={ cityId }
                        on:change=
                        {
                            () =>
                            {
                                city = _city;
                            }
                        }
                    />
                    <div class="font-size-90 font-weight-500 color-gray-300">
                        { getLocalizedText( _city.name, $languageTagStore ) } &#x2022; { getLocalizedText( _city.code, $languageTagStore ) }
                    </div>
                </label>
            { /each }
        { /if }
    </div>
</div>
