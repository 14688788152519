<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { fetchData } from '$lib/base';
    import { processParameters } from '$lib/parameters';
    import { urlParamsStore } from '$store/urlParamsStore';
    import { updateFeatureParameters, updateLocationParameters, updatePropertyParameters, updateBookingParameters } from '$store/filterParameterByKeyMapStore.js';
    import Properties from '$component/page/properties/Properties.svelte';
    import OnboardingModal from '$component/onboarding/OnboardingModal.svelte';
    import Seo from '../component/element/Seo.svelte';

    // -- VARIABLES

    export let arrivalDate;
    export let departureDate;
    export let selectedCountry;
    export let selectedCity;
    let propertyArray = [];
    let isCityProperty = true;
    let isLoading = true;
    let currentPage = 1;
    let hasMorePropertyPages = true;

    // -- FUNCTIONS

    async function loadData(
        propertyPage = 1
        )
    {
        isLoading = true;

        let urlParams = new URLSearchParams( window.location.search );
        let { propertyParameters, locationParameters, bookingParameters, featureParameters } = await processParameters( urlParams );

        updateBookingParameters( bookingParameters );
        updatePropertyParameters( propertyParameters );
        updateLocationParameters( locationParameters );
        updateFeatureParameters( featureParameters );

        let data
            = await fetchData(
                '/api/property/get',
                {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            propertyParameters: { ...propertyParameters, statusId: 'online' },
                            locationParameters,
                            bookingParameters,
                            featureParameters,
                            propertyPage,
                            propertyLimit: 10,
                            type: 'getPropertyArray'
                        }
                        ),
                    headers: { 'Content-Type': 'application/json' }
                }
                );

        if ( data && data.propertyArray )
        {
            if ( propertyPage > 1 )
            {
                propertyArray = [ ...propertyArray, ...data.propertyArray ];
            }
            else
            {
                propertyArray = data.propertyArray;
            }

            hasMorePropertyPages = data.hasMorePropertyPages;
            isCityProperty = data.isCityProperty;
        }
        else
        {
            propertyArray = [];
        }

        isLoading = false;
    }

    // ~~

    function loadMore(
        )
    {
        if ( !isLoading && hasMorePropertyPages )
        {
            currentPage++;
            loadData( currentPage );
        }
    }

    // ~~

    function handlePopState(
        )
    {
        loadData( 1 );
        currentPage = 1;
    }

    // -- STATEMENTS

    $: { $urlParamsStore; loadData( 1 ); }
    $: { $urlParamsStore; currentPage = 1; }

    // ~~

    onMount(
        () =>
        {
            window.addEventListener( 'popstate', handlePopState );

            return () =>
            {
                window.removeEventListener( 'popstate', handlePopState );
            };
        }
        );
</script>

<Seo
    metaTitle='Moien properties'
    metaDescription="Rent, sublet, live and travel! Discover a new rental experience and simplify your procedures with Moïen."
    url="https://moien.com/"
    path="search"
/>

<div>
    <Properties
        onNearBottom={ loadMore }
        isCityProperty={ isCityProperty }
        propertyArray={ propertyArray }
        selectedCity={ selectedCity }
        selectedCountry={ selectedCountry }
        arrivalDate={ arrivalDate }
        departureDate={ departureDate }
        isLoading={ isLoading }
    />
</div>
<OnboardingModal/>
