// -- IMPORTS

import { getFileLabel, getFileName } from "senselogic-gist";

// import { PUBLIC_MOIEN_STORAGE_URL } from '$env/static/public';

// -- FUNCTIONS

export function getStorageFilePath(
    filePath
    )
{
    if ( filePath )
    {
        if ( filePath.startsWith( '/global/' ) || filePath.startsWith( '/upload/' ) )
        {
            return 'https://rvmaltqvxnmtvljlghlx.supabase.co/storage/v1/object/public' + filePath;
        }
        else
        {
            return filePath;
        }
    }
}

// ~~

export function getStorageImagePath(
    imagePath,
    imageWidth
    )
{
    if ( !isNaN( imageWidth ) )
    {
        if ( imagePath )
        {
            let imageName = getFileName( imagePath );
            let lastUnderlineCharacterIndex = imageName.lastIndexOf( '_' );
            let lastDotCharacterIndex = imageName.lastIndexOf( '.' );
            let imageTimestamp = 0;

            if ( lastUnderlineCharacterIndex >= 0 )
            {
                imageTimestamp = imageName.slice( lastUnderlineCharacterIndex + 1, lastDotCharacterIndex );
            }

            if ( Number( imageTimestamp ) >= 20240911112716348000n  )
            {
                imagePath += '.' + imageWidth + '.avif';
            }
            if ( imagePath.endsWith( '.jpg' ) )
            {
                imagePath += '.' + imageWidth + '.jpg';
            }
            else if ( imagePath.endsWith( '.jpeg' ) )
            {
                imagePath += '.' + imageWidth + '.jpeg';
            }
            else if ( imagePath.endsWith( '.png' ) )
            {
                imagePath += '.' + imageWidth + '.png';
            }
        }
    }

    return getStorageFilePath( imagePath );
}
