<script>
    // -- IMPORTS

    import { navigate } from 'svelte-routing';
    import { getLocalizedTextBySlug, getRandomTuid } from 'senselogic-gist';
    import { fetchData, formatPrice, getLocalizedMonthName } from '$lib/base';
    import { setUrlQueryParameter } from '$lib/url';
    import { countNightsBetweenArrivalDateAndDepartureDate } from '$lib/booking';
    import { languageTagStore } from '$store/languageTagStore';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { authModalStore } from '$store/authModalStore';
    import { bookedPropertyStore, guestCounterStore } from '$store/bookingStore';
    import ModalButton from '$component/modal/ModalButton.svelte';
    import FilterDate from '$component/filter/FilterDate.svelte';
    import PropertyRules from '$component/page/property/PropertyRules.svelte';
    import Counter from '../ad/Counter.svelte';
    import PropertyGuestAccordion from './PropertyGuestAccordion.svelte';
    import PropertyVerifyIdentityModal from './PropertyVerifyIdentityModal.svelte';

    // -- VARIABLES

    export let property;
    export let cityName;
    export let visit = null;
    export let isScheduleVisitModalOpen = false;
    export let featureByTypeIdMap;
    export let propertyLeaseContract;
    export let profileDocumentByTypeIdMap = {};
    export let rentalBookingArray = [];
    export let modal = null;
    export let showPropertySummaryPopup;
    let termSelected = 'short-term';
    let availableForBothTerms = property.isAvailableForLongTerm && property.isAvailableForShortTerm;
    let dateContainerElement;
    let query = new URLSearchParams( window.location.search );
    let longTermMonthlyPrice = valueToNumber( property.longTermMonthlyPrice );
    let longTermAgencyFee = getLongTermExtraFee( 'long-term-agency-fee' );
    let longTermMonthlyCharge = getLongTermExtraFee( 'long-term-monthly-charges' );
    let longTermAnnualTaxes = getLongTermExtraFee( 'long-term-annual-taxes' );
    let isVerifyIdentityModalOpen = false;
    let longTermExtraFeeMonthly = longTermMonthlyCharge + longTermAnnualTaxes / 12;
    let longTermTotalPriceMonthly = longTermMonthlyPrice + longTermExtraFeeMonthly;
    let longTermTotalPriceFirstMonth = longTermMonthlyPrice + longTermAgencyFee + longTermExtraFeeMonthly;
    let totalGuestCount = 0;

    // -- FUNCTIONS

    function getLongTermExtraFee(
        featureTypeId
        )
    {
        let featureValue = Object.values( property.featureByIdMap ).filter( ( feature ) => feature.typeId === featureTypeId )[ 0 ]?.value ?? 0;

        return valueToNumber( featureValue );
    }

    // ~~

    function valueToNumber(
        value
        )
    {
        return ( Number.isNaN( Number( value ) ) ? 0 : Number( value ) );
    }

    // ~~

    function handleDatePicker(
        )
    {
        dateContainerElement.classList.toggle( 'is-selected' );
    }

    // ~~

    function handleDateSelected(
        event
        )
    {
        dateContainerElement.classList.toggle( 'is-selected' );
        filterParameterByKeyMapStore.update(
            currentParameters =>
            {
                let updatedParameters = { ...currentParameters };

                updatedParameters.bookingParameters.arrivalDate = event.detail.arrivalDate;
                updatedParameters.bookingParameters.departureDate = event.detail.departureDate;

                return updatedParameters;
            }
            );
        setUrlQueryParameter( query, 'arrivalDate', event.detail.arrivalDate );
        setUrlQueryParameter( query, 'departureDate', event.detail.departureDate );
    }

    // ~~

    async function handleCancelVisitRequest(
        )
    {
        let result
            = await fetchData(
                '/api/update-rental-visit',
                {
                    method: 'POST',
                    body:
                        JSON.stringify(
                            {
                                previousVisitId: visit ? visit.id : undefined,
                                visitId: visit.id,
                                visit: {}
                            }
                            ),
                    credentials: 'include'
                }
                );

        if ( result.visit )
        {
            visit = result.visit;
        }
        else
        {
            visit = null;
        }
    }

    // ~~

    async function handleAcceptVisitRequest(
        )
    {
        let result
            = await fetchData(
                '/api/update-rental-visit',
                {
                    method: 'POST',
                    body:
                        JSON.stringify(
                            {
                                visitId: visit.id,
                                visit: { status: 'booked' }
                            }
                            ),
                    credentials: 'include'
                }
                );

        visit = result.visit;
    }

    // ~~

    function goToBooking(
        )
    {
        if( !profileSignedInStore )
        {
            $authModalStore = 'sign-in';
        }
        else if ( profileDocumentByTypeIdMap[ 'id-card' ] === undefined || profileDocumentByTypeIdMap[ 'id-card' ] === null )
        {
            isVerifyIdentityModalOpen = true;
        }
        else
        {
            if ( shortTermNightsCount > 0 )
            {
                $bookedPropertyStore = { ...property, cityName: cityName }

                $guestCounterStore =
                {
                    adult: $filterParameterByKeyMapStore.bookingParameters.adultCount,
                    children: $filterParameterByKeyMapStore.bookingParameters.childrenCount,
                    infant: $filterParameterByKeyMapStore.bookingParameters.infantCount,
                    pet: $filterParameterByKeyMapStore.bookingParameters.petCount ?? 0
                }
                navigate( '/booking/checkout/' + getRandomTuid() );
            }
        }
    }

    // -- STATEMENTS

    $: totalGuestCount =
        ( $filterParameterByKeyMapStore?.bookingParameters?.adultCount ?? 0 )
        + ( $filterParameterByKeyMapStore?.bookingParameters?.childrenCount ?? 0 )
        + ( $filterParameterByKeyMapStore?.bookingParameters?.infantCount ?? 0 );

    // ~~

    $: shortTermNightsCount
        = countNightsBetweenArrivalDateAndDepartureDate(
            $filterParameterByKeyMapStore.bookingParameters.arrivalDate,
            $filterParameterByKeyMapStore.bookingParameters.departureDate
            );

    // ~~

    $: shortTermTotalPrice =
        shortTermNightsCount > 1
        ? shortTermNightsCount * property.shortTermDailyPrice
        : property.shortTermDailyPrice;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .property-reserve
    {
        display: none;
        flex-direction: column;
        gap: 1rem;

        +media( desktop )
        {
            z-index: 1;
            position: sticky;
            top: 5.5rem;

            margin-top: 1.5rem;
            max-width: 27.875rem;
            border-radius: 1.5rem;
            padding: 1.5rem;

            display: flex;

            background-color: grayColor950;
            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }

    .property-reserve-modal
    {
        margin-top: 5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        +media( desktop )
        {
            z-index: 1;
            position: sticky;
            top: 5.5rem;

            margin-top: 1.5rem;
            border-radius: 1.5rem;
            padding: 1.5rem;

            background-color: grayColor950;
            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }

    .property-reserve-close-modal
    {
        display: flex;
        justify-content: flex-end;
        button
        {
            border: none;

            background-color: transparent;
        }
    }

    .property-reserve-modal .property-reserve-button
    {
        width: 100%;
        border-radius: 0.75rem;
        padding: 0.75rem 1.5rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;

        background-color: blueColor;
    }

    .property-reserve-toggler-modal
    {
        border: 2px solid grayColor700;
        border-radius: 1rem;

        display: flex;

        background-color: grayColor900;

        cursor: pointer;
    }

    .property-reserve-toggler-item
    {
        width: 50%;
        border-radius: 0.5rem;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        padding: 1rem 1.5rem;

        background-color: transparent;

        text-align: center;

        cursor: pointer;
        transition: all 400ms ease-in-out, color 400ms ease-in-out;

        +media( desktop )
        {
            &:hover
            {
                background-color: grayColor950;

                color: greenColor;

                transition: all 400ms ease-in-out, color 400ms ease-in-out;
            }
        }
    }

    .property-reserve-toggler-item.is-selected
    {
        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        color: greenColor;
    }

    .property-reserve-short-term,
    .property-reserve-long-term
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .property-reserve-short-term,
    {
        border-bottom: 2px solid grayColor700;
        padding-bottom: 2rem;

        +media( desktop )
        {
            border-bottom: unset;
            padding-bottom: 0;
        }
    }

    .property-reserve-short-term-date-container
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .property-reserve-short-term-date-item
    {
        min-width: 20vh;
        border: 2px solid grayColor700;
        border-radius: 1rem;
        padding: 1.5rem 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        background-color: grayColor950;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }

    .property-reserve-short-term-date-picker
    {
        display: none;
    }

    .property-reserve-long-term-button
    {
        border-radius: 0.75rem;
        padding: 0.75rem 1.5rem;

        background-color: blueColor;
    }

    :global( .property-reserve-short-term-date-container.is-selected~.property-reserve-short-term-date-picker )
    {
        margin-top: 1rem;

        display: block;
    }

    :global( .property-reserve-short-term-date-picker >div )
    {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .property-reserve-guest-number
    {
        margin-top: 1rem;
        border-radius: 1rem;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        background-color: grayColor900;
    }

    .property-reserve-guest-counter
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .counter-divider
    {
        border-bottom: 1px solid grayColor700;
        padding-bottom: 1rem;
    }

    .property-reserve-price-rules
    {
        border: 2px solid grayColor700;
        border-radius: 1rem;
        padding: 1.25rem 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        background-color: grayColor900;
    }

    .property-reserve-price-rules-container,
    .property-reserve-price-rules-total-container
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .property-reserve-price-rules-total-container
    {
        border-top: 1px solid grayColor700;
        padding-top: 1rem;
    }
</style>

<PropertyVerifyIdentityModal bind:isOpen={ isVerifyIdentityModalOpen } />
{ #if modal }
    <div class="property-reserve-modal">
        <div class="property-reserve-close-modal">
            <button on:click={ () => modal = false }>
                <div class="red-close-icon size-150"/>
            </button>
        </div>
        { #if availableForBothTerms }
            <div class="property-reserve-toggler-modal">
                <button
                    class="font-size-90 font-weight-700 color-gray property-reserve-toggler-item"
                    class:is-selected={ termSelected === 'short-term' }
                    on:click={ () => ( termSelected = 'short-term' ) }
                >
                    { getLocalizedTextBySlug( 'short-term-label', $languageTagStore ) }
                </button>
                <button
                    class="font-size-90 font-weight-700 color-gray property-reserve-toggler-item"
                    class:is-selected={ termSelected === 'long-term' }
                    on:click={ () => ( termSelected = 'long-term' ) }
                >
                    { getLocalizedTextBySlug( 'long-term-label', $languageTagStore ) }
                </button>
            </div>
        { /if }
        { #if property.isAvailableForShortTerm && ( availableForBothTerms ? termSelected === 'short-term' : true ) }
            <div class="property-reserve-short-term">
                <div class="property-reserve-short-term-date">
                    <div class="property-reserve-short-term-date-container" bind:this={ dateContainerElement }>
                        <button class="property-reserve-short-term-date-item" on:click={ handleDatePicker }>
                            <div class="font-size-75 font-weight-500 color-gray property-reserve-short-term-date-label">
                                { getLocalizedTextBySlug( 'date-from-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-short-term-date-value">
                                { #if $filterParameterByKeyMapStore.bookingParameters.arrivalDate }
                                    {
                                        new Date( $filterParameterByKeyMapStore.bookingParameters.arrivalDate )
                                            .toLocaleString( $languageTagStore, { dateStyle: 'short', timeZone: 'UTC' } )
                                    }
                                { /if }
                            </div>
                        </button>
                        <div class="green-right-arrow-icon size-150 property-reserve-short-term-date-icon">
                        </div>
                        <button class="property-reserve-short-term-date-item" on:click={ handleDatePicker }>
                            <div class="font-size-75 font-weight-500 color-gray property-reserve-short-term-date-label">
                                { getLocalizedTextBySlug( 'date-to-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-short-term-date-value">
                                { #if $filterParameterByKeyMapStore.bookingParameters.departureDate }
                                    {
                                        new Date( $filterParameterByKeyMapStore.bookingParameters.departureDate )
                                            .toLocaleString( $languageTagStore, { dateStyle: 'short', timeZone: 'UTC' } )
                                    }
                                { /if }
                            </div>
                        </button>
                    </div>
                    <div class="property-reserve-short-term-date-picker">
                        <FilterDate
                            dateRange=
                            {
                                $filterParameterByKeyMapStore.bookingParameters.departureDate
                                ? [ new Date( $filterParameterByKeyMapStore.bookingParameters.arrivalDate ),
                                    new Date( $filterParameterByKeyMapStore.bookingParameters.departureDate ) ]
                                : [ new Date(), new Date() ]
                            }
                            rentalBookingArray={ rentalBookingArray }
                            on:dateSelected={ handleDateSelected }
                        />
                    </div>
                </div>
                <PropertyGuestAccordion
                    label={ getLocalizedTextBySlug( 'who-label', $languageTagStore) }
                    actionLabel="{ totalGuestCount } { getLocalizedTextBySlug( 'filter-guests-label', $languageTagStore ) }"
                >
                    <div class="property-reserve-guest-number">
                        <div class="property-reserve-guest-counter counter-divider">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-adult-label', $languageTagStore ) }
                            </div>
                            <Counter bind:count={ $filterParameterByKeyMapStore.bookingParameters.adultCount }/>
                        </div>
                        <div class="property-reserve-guest-counter counter-divider">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-children-label', $languageTagStore ) }
                            </div>
                            <Counter bind:count={ $filterParameterByKeyMapStore.bookingParameters.childrenCount }/>
                        </div>
                        <div class="property-reserve-guest-counter">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-infant-label', $languageTagStore ) }
                            </div>
                            <Counter bind:count={ $filterParameterByKeyMapStore.bookingParameters.infantCount }/>
                        </div>
                    </div>
                </PropertyGuestAccordion>
                <div class="property-reserve-price-rules">
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray property-reserve-price-rules-label">
                            { #if shortTermNightsCount > 1 }
                                { formatPrice( property.shortTermDailyPrice, $languageTagStore ) }
                                x { shortTermNightsCount }
                                { getLocalizedTextBySlug( 'nights-label', $languageTagStore ) }
                            { :else }
                                { getLocalizedTextBySlug( 'price-per-night-label', $languageTagStore ) }
                            { /if }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( shortTermTotalPrice, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            -/-
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-total-container">
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-label">
                            { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-price">
                            { formatPrice( shortTermTotalPrice, $languageTagStore ) }
                        </div>
                    </div>
                </div>
                <PropertyRules featureByTypeIdMap={ featureByTypeIdMap } />
                <button
                    class="property-reserve-button"
                    on:click={ goToBooking }
                    disabled={ shortTermNightsCount < 1 || $profileSignedInStore?.userId === property.userId }
                >
                    <span class="font-size-100 font-weight-700 color-white">
                        { getLocalizedTextBySlug( 'reserve-label', $languageTagStore ) }
                    </span>
                </button>
            </div>
        { /if }
        { #if property.isAvailableForLongTerm && ( availableForBothTerms ? termSelected === 'long-term' : true ) }
            <div class="property-reserve-long-term">
                <div class="property-reserve-price-rules">
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'filter-price-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( property.longTermMonthlyPrice, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-monthly-charges-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermMonthlyCharge, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-annual-taxes-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermAnnualTaxes, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-agency-fee-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermAgencyFee ) }
                        </div>
                    </div>
                    { #if longTermAgencyFee > 0 }
                        <div class="property-reserve-price-rules-total-container">
                            <div class="font-size-100 font-weight-500 color-black property-reserve-price-rules-total-label">
                                { getLocalizedTextBySlug( 'total-first-month-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-total-price">
                                { formatPrice( longTermTotalPriceFirstMonth, $languageTagStore ) }
                            </div>
                        </div>
                    { /if }
                    <div class="property-reserve-price-rules-total-container">
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-label">
                            { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-price">
                            { formatPrice( longTermTotalPriceMonthly ) }
                        </div>
                    </div>
                </div>
                <div class="font-size-90 font-weight-500 color-black property-reserve-long-term-avaiability">
                    { getLocalizedTextBySlug( 'available-from-label', $languageTagStore ) } { getLocalizedMonthName( 6 ) }, 2024
                </div>
                { #if propertyLeaseContract !== null }
                    <ModalButton
                        variant="contained"
                        text={ getLocalizedTextBySlug( 'property-rental-edit-lease-contract-label', $languageTagStore ) }
                        on:click={ () => navigate( '/dashboard/edit-lease-contract/' + propertyLeaseContract.id ) }
                    />
                { :else if visit !== null }
                    { #if visit.status === 'rescheduled-by-host' }
                        <ModalButton
                            variant="contained"
                            text={ getLocalizedTextBySlug( 'property-rental-accept-reschedule-label', $languageTagStore ) }
                            on:click={ handleAcceptVisitRequest }
                        />
                    { /if }
                    <ModalButton
                        variant={ visit.status === 'rescheduled-by-host' ? 'text' : 'contained' }
                        text={ getLocalizedTextBySlug( 'property-rental-cancel-visit-request-label', $languageTagStore ) }
                        on:click={ handleCancelVisitRequest }
                    />
                { :else }
                    <button
                        class="font-size-100 font-weight-700 color-white property-reserve-long-term-button"
                        on:click={ () => { isScheduleVisitModalOpen = true; modal = false } }
                    >
                        { getLocalizedTextBySlug( 'book-visit-label', $languageTagStore ) }
                    </button>
                { /if }
                <div class="font-size-90 font-weight-500 color-gray property-reserve-long-term-avaiability">
                    { getLocalizedTextBySlug( 'cancelation-disclosure-text', $languageTagStore ) }
                </div>
            </div>
        { /if }
    </div>
{ :else }
    <div class="property-reserve">
        { #if availableForBothTerms }
            <div class="property-reserve-toggler-modal">
                <button
                    class="font-size-90 font-weight-700 color-gray property-reserve-toggler-item"
                    class:is-selected={ termSelected === 'short-term' }
                    on:click={ () => ( termSelected = 'short-term' ) }
                >
                    { getLocalizedTextBySlug( 'short-term-label', $languageTagStore ) }
                </button>
                <button
                    class="font-size-90 font-weight-700 color-gray property-reserve-toggler-item"
                    class:is-selected={ termSelected === 'long-term' }
                    on:click={ () => ( termSelected = 'long-term' ) }
                >
                    { getLocalizedTextBySlug( 'long-term-label', $languageTagStore ) }
                </button>
            </div>
        { /if }
        { #if property.isAvailableForShortTerm && ( availableForBothTerms ? termSelected === 'short-term' : true ) }
            <div class="property-reserve-short-term">
                <div class="property-reserve-short-term-date">
                    <div class="property-reserve-short-term-date-container" bind:this={ dateContainerElement }>
                        <button class="property-reserve-short-term-date-item" on:click={ handleDatePicker }>
                            <div class="font-size-75 font-weight-500 color-gray property-reserve-short-term-date-label">
                                { getLocalizedTextBySlug( 'date-from-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-short-term-date-value">
                                { #if $filterParameterByKeyMapStore.bookingParameters.arrivalDate }
                                    {
                                        new Date( $filterParameterByKeyMapStore.bookingParameters.arrivalDate )
                                            .toLocaleString( $languageTagStore, { dateStyle: 'short', timeZone: 'UTC' } )
                                    }
                                { /if }
                            </div>
                        </button>
                        <div class="green-right-arrow-icon size-150 property-reserve-short-term-date-icon">
                        </div>
                        <button class="property-reserve-short-term-date-item" on:click={ handleDatePicker }>
                            <div class="font-size-75 font-weight-500 color-gray property-reserve-short-term-date-label">
                                { getLocalizedTextBySlug( 'date-to-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-short-term-date-value">
                                { #if $filterParameterByKeyMapStore.bookingParameters.departureDate }
                                    {
                                        new Date( $filterParameterByKeyMapStore.bookingParameters.departureDate )
                                            .toLocaleString( $languageTagStore, { dateStyle: 'short', timeZone: 'UTC' } )
                                    }
                                { /if }
                            </div>
                        </button>
                    </div>
                    <div class="property-reserve-short-term-date-picker">
                        <FilterDate
                            dateRange=
                            {
                                $filterParameterByKeyMapStore.bookingParameters.departureDate
                                ? [ new Date( $filterParameterByKeyMapStore.bookingParameters.arrivalDate ),
                                    new Date( $filterParameterByKeyMapStore.bookingParameters.departureDate ) ]
                                : [ new Date(), new Date() ]
                            }
                            rentalBookingArray={ rentalBookingArray }
                            on:dateSelected={ handleDateSelected }
                        />
                    </div>
                </div>
                <PropertyGuestAccordion
                    label={ getLocalizedTextBySlug( 'who-label', $languageTagStore) }
                    actionLabel="{ totalGuestCount } { getLocalizedTextBySlug( 'filter-guests-label', $languageTagStore ) }"
                >
                    <div class="property-reserve-guest-number">
                        <div class="property-reserve-guest-counter counter-divider">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-adult-label', $languageTagStore ) }
                            </div>
                            <Counter
                                bind:count={ $filterParameterByKeyMapStore.bookingParameters.adultCount }
                            />
                        </div>
                        <div class="property-reserve-guest-counter counter-divider">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-children-label', $languageTagStore ) }
                            </div>
                            <Counter
                                bind:count={ $filterParameterByKeyMapStore.bookingParameters.childrenCount }
                            />
                        </div>
                        <div class="property-reserve-guest-counter">
                            <div class="font-size-100 font-weight-700 color-gray-100">
                                { getLocalizedTextBySlug( 'guest-infant-label', $languageTagStore ) }
                            </div>
                            <Counter
                                bind:count={ $filterParameterByKeyMapStore.bookingParameters.infantCount }
                            />
                        </div>
                    </div>
                </PropertyGuestAccordion>
                <div class="property-reserve-price-rules">
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray property-reserve-price-rules-label">
                            { #if shortTermNightsCount > 1 }
                                { formatPrice( property.shortTermDailyPrice, $languageTagStore ) }
                                x { shortTermNightsCount }
                                { getLocalizedTextBySlug( 'nights-label', $languageTagStore ) }
                            { :else }
                                { getLocalizedTextBySlug( 'price-per-night-label', $languageTagStore ) }
                            { /if }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( shortTermTotalPrice, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            -/-
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-total-container">
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-label">
                            { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-price">
                            { formatPrice( shortTermTotalPrice, $languageTagStore ) }
                        </div>
                    </div>
                </div>
                <PropertyRules featureByTypeIdMap={ featureByTypeIdMap } />
                <ModalButton
                    variant="light"
                    text={ getLocalizedTextBySlug( 'summary-label', $languageTagStore ) }
                    on:click={ () => ( showPropertySummaryPopup = true ) }
                />
                <ModalButton
                    text={ getLocalizedTextBySlug( 'reserve-label', $languageTagStore ) }
                    on:click={ goToBooking }
                    disabled={ shortTermNightsCount < 1 || $profileSignedInStore?.userId === property.userId }
                />
            </div>
        { /if }
        { #if property.isAvailableForLongTerm && ( availableForBothTerms ? termSelected === 'long-term' : true ) }
            <div class="property-reserve-long-term">
                <div class="property-reserve-price-rules">
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'filter-price-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( property.longTermMonthlyPrice, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-monthly-charges-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermMonthlyCharge, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-annual-taxes-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermAnnualTaxes, $languageTagStore ) }
                        </div>
                    </div>
                    <div class="property-reserve-price-rules-container">
                        <div class="font-size-90 font-weight-500 color-gray  property-reserve-price-rules-label">
                            { getLocalizedTextBySlug( 'extra-fees-agency-fee-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-label">
                            { formatPrice( longTermAgencyFee, $languageTagStore ) }
                        </div>
                    </div>
                    { #if longTermAgencyFee > 0 }
                        <div class="property-reserve-price-rules-total-container">
                            <div class="font-size-100 font-weight-500 color-black property-reserve-price-rules-total-label">
                                { getLocalizedTextBySlug( 'total-first-month-label', $languageTagStore ) }
                            </div>
                            <div class="font-size-90 font-weight-700 color-black property-reserve-price-rules-total-price">
                                { formatPrice( longTermTotalPriceFirstMonth, $languageTagStore ) }
                            </div>
                        </div>
                    { /if }
                    <div class="property-reserve-price-rules-total-container">
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-label">
                            { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
                        </div>
                        <div class="font-size-100 font-weight-700 color-black property-reserve-price-rules-total-price">
                            { formatPrice( longTermTotalPriceMonthly, $languageTagStore ) }
                        </div>
                    </div>
                </div>
                <div class="font-size-90 font-weight-500 color-black property-reserve-long-term-avaiability">
                    { getLocalizedTextBySlug( 'available-from-label', $languageTagStore ) } { getLocalizedMonthName( 6 ) }, 2024
                </div>
                { #if propertyLeaseContract !== null }
                    <ModalButton
                        variant="contained"
                        text={ getLocalizedTextBySlug( 'property-rental-edit-lease-contract-label', $languageTagStore ) }
                        on:click={ () => navigate( '/dashboard/edit-lease-contract/' + propertyLeaseContract.id ) }
                    />
                { :else if visit !== null }
                    { #if visit.status === 'rescheduled-by-host' }
                        <ModalButton
                            variant="contained"
                            text={ getLocalizedTextBySlug( 'property-rental-accept-reschedule-label', $languageTagStore ) }
                        />
                    { /if }
                    <ModalButton
                        variant={ visit.status === 'rescheduled-by-host' ? 'text' : 'contained' }
                        text={ getLocalizedTextBySlug( 'property-rental-cancel-visit-request-label', $languageTagStore ) }
                        on:click={ handleCancelVisitRequest }
                    />
                { :else }
                    <ModalButton
                        text={ getLocalizedTextBySlug( 'book-visit-label', $languageTagStore ) }
                        on:click={ () => isScheduleVisitModalOpen = true }
                    />
                { /if }
                <div class="font-size-90 font-weight-500 color-gray property-reserve-long-term-avaiability">
                    { getLocalizedTextBySlug( 'cancelation-disclosure-text', $languageTagStore ) }
                </div>
            </div>
        { /if }
    </div>
{ /if }
