<script>
    // -- IMPORTS

    import { slide } from 'svelte/transition';
    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { isDesktopMapOpenStore, isDesktopListOpenStore, isMobileMapOpenStore, isMobileListOpenStore } from '$store/locationStore';
    import { languageTagStore } from '$store/languageTagStore';
    import PropertyLoading from '$component/page/properties/PropertyLoading.svelte';
    import PropertyCard from '$component/page/properties/PropertyCard.svelte';

    // -- VARIABLES

    export let propertyArray;
    export let isCityProperty;
    export let selectedCity;
    export let selectedCountry;
    export let isLoading;
    export let onNearBottom;

    // -- FUNCTIONS

    function onScroll(
        event
        )
    {
        let bottomReached = event.target.scrollHeight - event.target.scrollTop - 1 <= event.target.clientHeight;

        if ( bottomReached && onNearBottom )
        {
            onNearBottom();
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .properties-list
    {
        height: calc( var( --viewport-height ) - 11.75rem );

        display: block;

        +media( desktop )
        {
            height: calc( var( --viewport-height ) - 11rem );
        }
    }

    .properties-list-message
    {
        padding: 0.5rem 1.5rem;

        text-align: left;
    }

    .properties-list-container
    {
        overflow-y: auto;
        height: 100%;
        padding: 0 1.5rem 6.5rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        -ms-overflow-style: none;
        scrollbar-width: none;

        +media( tablet )
        {
            display: grid;
            grid-template-columns: repeat( auto-fill, minmax( 18rem, 1fr ) );
            grid-auto-rows: min-content;
        }

        +media( desktop )
        {
            padding: 0 1.5rem 6.5rem 1.5rem;
        }
    }

    .properties-list-container::-webkit-scrollbar
    {
        display: none;
    }

    .properties-list-button, .properties-list-map-button
    {
        z-index: 998;
        position: absolute;
        bottom: 0rem;
        transform: translate( -50%, -50% );

        border-radius: 0.5rem;
        padding: 0.875rem 2.5rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        >div
        {
            transition: background-color 400ms ease-in-out;
        }
    }

    .properties-list-button:hover
    {
        +media( desktop )
        {
            border-color: blueColor300;

            color: blueColor300;
            >div
            {
                background-color: blueColor300;

                transition: background-color 400ms ease-in-out;
            }
        }
    }

    .properties-list-map-button:hover
    {
        background-color: blueColor500;

        color: whiteColor;
    }

    .properties-list-map-button
    {
        left: 50%;

        display: flex;

        background-color: blueColor300;

        transition: background-color 400ms ease-in-out;
    }

    .properties-list-button
    {
        left: 41%;

        border: 1px solid blueColor;

        display: none;

        background-color: whiteColor;

        +media( desktop )
        {
            display: flex;
        }
    }

    .properties-list.is-mobile-map-open .properties-list-map-button
    {
        display: none;
    }

    .properties-list.is-desktop-map-open.is-desktop-list-open .properties-list-map-button
    {
        +media( desktop )
        {
            display: none;
        }
    }
</style>

<div
    class="properties-list"
    class:is-mobile-map-open={ $isMobileMapOpenStore }
    class:is-mobile-list-open={ $isMobileListOpenStore }
    class:is-desktop-map-open={ $isDesktopMapOpenStore }
    class:is-desktop-list-open={ $isDesktopListOpenStore }
    transition:slide
>
    { #if propertyArray.length > 0 }
        <div class="properties-list-message">
            <div class="font-size-125 font-weight-700 color-gray">
                { #if selectedCity && isCityProperty }
                    { propertyArray.length }
                    { getLocalizedTextBySlug( 'property-list-results-in-label', $languageTagStore ) }
                    { getLocalizedText( selectedCity.name, $languageTagStore ) }
                { :else if selectedCity && selectedCountry }
                    { getLocalizedTextBySlug( 'property-list-closer-results-label', $languageTagStore ) }
                    { getLocalizedText( selectedCity.name, $languageTagStore ) }
                { /if }
            </div>
        </div>
    { :else }
        <div class="properties-list-message">
            <div class="font-size-125 font-weight-700 color-gray">
                { getLocalizedTextBySlug( 'property-list-no-results-label', $languageTagStore ) }
            </div>
        </div>
    { /if }
    <div
        class="properties-list-container"
        on:scroll={ ( event ) => onScroll( event ) }
    >
        { #if propertyArray }
            { #if propertyArray.length > 0 }
                { #each propertyArray as property }
                    <PropertyCard property={ property } />
                { /each }
            { /if }
        { /if }
        { #if isLoading }
            <PropertyLoading/>
        { /if }
    </div>
    { #if propertyArray.length > 0 }
        <button
            class="font-size-90 font-weight-700 color-white properties-list-map-button"
            on:click={ () => { $isDesktopMapOpenStore = true; $isMobileMapOpenStore = true; } }
        >
            <div class="white-map-icon size-150"/>
            { getLocalizedTextBySlug( 'property-list-show-map-button', $languageTagStore ) }
        </button>
    { /if }
    { #if propertyArray.length > 0 }
        { #if $isDesktopMapOpenStore }
            <button
                class="font-size-100 font-weight-700 color-blue properties-list-button"
                on:click={ () => ( $isDesktopListOpenStore = false ) }
            >
                <div class="blue-list-icon size-150"/>
                { getLocalizedTextBySlug( 'property-list-close-list-button', $languageTagStore ) }
            </button>
        { /if }
    { /if }
</div>
