<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { getMap } from 'senselogic-gist';
    import { fetchData } from '$lib/base';
    import { getValueByTypeId } from '$lib/base';
    import Loading from '$component/element/Loading.svelte';
    import PropertyHeading from '$component/page/property/PropertyHeading.svelte';
    import PropertyDetail from '$component/page/property/PropertyDetail.svelte';
    import PropertyDescription from '$component/page/property/PropertyDescription.svelte';
    import PropertyEquipment from '$component/page/property/PropertyEquipment.svelte';
    import PropertyLocation from '$component/page/property/PropertyLocation.svelte';
    import PropertyHost from '$component/page/property/PropertyHost.svelte';
    import PropertyRentalReview from '$component/page/property/PropertyRentalReview.svelte';
    import PropertyReserve from '$component/page/property/PropertyReserve.svelte';
    import PropertyPark from '$component/page/property/PropertyPark.svelte';
    import PropertyRules from '$component/page/property/PropertyRules.svelte';
    import PropertyVisit from '$component/page/property/PropertyVisit.svelte';
    import PropertyScheduleVisit from '$component/page/property/PropertyScheduleVisit.svelte';
    import PropertyVisitResultModal from '$component/page/property/PropertyVisitResultModal.svelte';
    import PropertyCancellationPolicy from '$component/page/property/PropertyCancellationPolicy.svelte';
    import Seo from '$component/element/Seo.svelte';

    // -- VARIABLES

    export let id;

    let isLoading = true;
    let property;
    let profile;
    let profilePropertyArrayLength;
    let propertyRentalReviewArray = [];
    let visit = null;
    let visitResult = '';
    let documentTypeMap;
    let documentArray = [];
    let profileDocumentByTypeIdMap;
    let isScheduleVisitModalOpen = false;
    let isVisitResultModalOpen = false;
    let userHasAllDocuments = false;
    let hasCompleteRentalFile = false;
    let propertyFeatureByTypeIdMap = {};
    let propertyLeaseContract = null;
    let rentalBookingArray = [];
    let questionArray =
    [
        {
            title: 'Question 1',
            profile:
            {
                firstName: 'First Name 1',
                imagePath: ''
            },
            description: 'Description 1',
            answer: 'Answer 1'
        },
        {
            title: 'Question 2',
            profile:
            {
                firstName: 'First Name 2',
                imagePath: ''
            },
            description: 'Description 2',
            answer: 'Answer 2'
        },
        {
            title: 'Question 3',
            profile:
            {
                firstName: 'First Name 3',
                imagePath: ''
            },
            description: 'Description 3',
            answer: 'Answer 3'
        }
    ];

    // -- FUNCTIONS

    function getUserHasAllDocuments(
        )
    {
        for ( let documentTypeId of property.requiredLongTermDocumentTypeIdList )
        {
            if ( !profileDocumentByTypeIdMap[ documentTypeId ] )
            {
                return false;
            }
        }

        return true;
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            try
            {
                let data = await fetchData( `/api/page/property/${ id }`, { method: 'POST', credentials: 'include' } );
                property = data.property;
                profile = data.profile;
                profilePropertyArrayLength = data.profilePropertyCount;
                propertyRentalReviewArray = data.propertyRentalReviewArray;
                visit = data.visit;
                documentTypeMap = data.documentTypeMap;
                documentArray = data.documentArray;
                profileDocumentByTypeIdMap = data.profileDocumentByTypeIdMap;
                userHasAllDocuments = getUserHasAllDocuments();
                hasCompleteRentalFile = data.hasCompleteRentalFile;
                propertyFeatureByTypeIdMap = getMap( property.featureArray, 'typeId' );
                questionArray = data.questionArray;
                propertyLeaseContract = data.propertyLeaseContract;
                rentalBookingArray = data.rentalBookingArray;
            }
            catch ( error )
            {
                console.error( 'Error :', error );
            }
            finally
            {
                isLoading = false;
            }
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../constant.styl';
    @import '../../mixin.styl';

    // -- CLASSES

    .property
    {
        overflow-y: scroll;
        height: calc( 100dvh - 4.5rem );

        +media( desktop )
        {
            overflow-y: default;
            margin: auto;
            height: auto;
            max-width: pageMaxContentWidth;
            padding: 1.5rem 2rem 5rem 2rem;
        }
    }

    .property-container
    {
        padding: 0 1rem 10rem 1rem;

        +media( desktop )
        {
            padding: 0;

            display: flex;
            flex-direction: row;
            gap: 5rem;
        }
    }

    .property-container-main
    {
        flex: 1;
    }

    .property-container-reserve
    {
        width: 100%;

        +media( desktop )
        {
            max-width: 30rem;
        }
    }

    .property-rules-container
    {
        border-top: 1px solid lightGrayBorderColor;
        padding: 1.5rem 0;
    }
</style>

{ #if property }
    <Seo
        metaTitle={ property.title }
        metaDescription={ property.description }
        imagePath={ property.imagePath }
        url="https://moien.com/"
        path={`property/${ id }`}
    />
{ /if }

{ #if isLoading }
    <Loading/>
{ :else if property }
    <div class="property">
        <PropertyHeading
            propertyId={ property.id }
            title={ property.title }
            averageRating={ property.averageRating }
            countryName={ property.countryName }
            cityName={ property.cityName }
            imagePathArray={ property.imagePathArray }
        />
        <div class="property-container">
            <div class="property-container-main">
                <PropertyDetail
                    userProfile={ profile }
                    bedroomCount={ Number( getValueByTypeId( 'bedroom-count', property.featureByIdMap ) ) }
                    bathroomCount={ Number( getValueByTypeId( 'bathroom-count', property.featureByIdMap ) ) }
                    totalArea={ getValueByTypeId( 'property-area', property.featureByIdMap ) }
                />
                <PropertyDescription
                    description={ property.description }
                />
                { #if visit }
                    <PropertyVisit
                        visit={ visit }
                        bind:isScheduleVisitModalOpen={ isScheduleVisitModalOpen }
                    />
                { /if }
                <PropertyPark
                    freeParking={ true }
                    freeCancellationHours={ 48 }
                />
                <PropertyEquipment
                    featureArray={ property.featureArray }
                />
                <PropertyLocation
                    propertyLatitude={ property.latitude }
                    propertyLongitude={ property.longitude }
                    countryName={ property.countryName }
                    cityName={ property.cityName }
                />
                <PropertyHost
                    property={ property }
                    profile={ profile }
                    profilePropertyArrayLength={ profilePropertyArrayLength }
                />
                <PropertyRentalReview
                    propertyRentalReviewArray={ propertyRentalReviewArray }
                />
                <div class="property-rules-container">
                    <PropertyRules
                        featureByTypeIdMap={ propertyFeatureByTypeIdMap }
                    />
                </div>
                <PropertyCancellationPolicy
                    featureByTypeIdMap={ propertyFeatureByTypeIdMap }
                    featureArray={ property.featureArray }
                />
            </div>
            <div class="property-container-reserve">
                <PropertyReserve
                    property={ property }
                    cityName={ property.cityName }
                    featureByTypeIdMap={ propertyFeatureByTypeIdMap }
                    propertyLeaseContract={ propertyLeaseContract }
                    profileDocumentByTypeIdMap={ profileDocumentByTypeIdMap }
                    rentalBookingArray={ rentalBookingArray }
                    bind:isScheduleVisitModalOpen={ isScheduleVisitModalOpen }
                    bind:visit={ visit }
                />
            </div>
        </div>
    </div>
    { #if isScheduleVisitModalOpen }
        <PropertyScheduleVisit
            property={ property }
            userHasAllDocuments={ userHasAllDocuments }
            hasCompleteRentalFile={ hasCompleteRentalFile }
            bind:isOpen={ isScheduleVisitModalOpen }
            bind:visit={ visit }
            bind:isVisitResultModalOpen={ isVisitResultModalOpen }
            bind:visitResult={ visitResult }
        />
    { /if }
    { #if isVisitResultModalOpen  }
        <PropertyVisitResultModal
            documentTypeMap={ documentTypeMap }
            requiredDocumentTypeIdArray={ property.requiredLongTermDocumentTypeIdList }
            bind:isOpen={ isVisitResultModalOpen }
            bind:visitResult={ visitResult }
        />
    { /if }
{ /if }
