<script>

    // -- IMPORTS

    import { filterParameterByKeyMapStore, updateBookingParameters } from '$store/filterParameterByKeyMapStore.js';
    import FilterBudget from '$component/filter/FilterBudget.svelte';
    import OnboardingAction from '$component/onboarding/OnboardingAction.svelte';

    // -- VARIABLES

    export let goToNextStep;

    let termType;

    // -- STATEMENTS

    if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForShortTerm' ] )
    {
        termType = 'short-term';
    }
    else if ( $filterParameterByKeyMapStore.propertyParameters[ 'isAvailableForLongTerm' ] )
    {
        termType = 'long-term';
    }

    // -- FUNCTIONS

    function handleBudgetSelected(
        event
        )
    {
        if ( termType === 'short-term' )
        {
            updateBookingParameters( { 'minimumDailyBudget': event.detail.minimumDailyBudget } );
            updateBookingParameters( { 'maximumDailyBudget': event.detail.maximumDailyBudget } );
        }
        else if ( termType === 'long-term' )
        {
            updateBookingParameters( { 'minimumMonthlyBudget': event.detail.minimumMonthlyBudget } );
            updateBookingParameters( { 'maximumMonthlyBudget': event.detail.maximumMonthlyBudget } );
        }
    }
</script>

<style>
    .onboarding-budget::before
    {
        background: url( '/image/onboarding/onboarding_budget.png' ) no-repeat center center;
    }
</style>

<div class="onboarding-budget">
    <FilterBudget
        termType= { termType }
        on:budgetSelected={ handleBudgetSelected }
    />
    <OnboardingAction
        goToNextStep={ goToNextStep }
    />
</div>
