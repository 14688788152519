<script>
    // -- VARIABLES

    export let variant = 'contained';
    export let label = '';
    export let type = 'text';
    export let value;
    export let min = null;
    export let max = null;
    export let suffix = null;
    export let placeholder = null;
    export let name = '';
    export let onChange = () => {};
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .labelled-input
    {
        width: 100%;
        border-bottom: 1px solid lightGrayBorderColor;
        padding: 0.75rem 0;

        display: flex;
        flex-direction: column;
    }

    .contained
    {
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 0.75rem 1rem;

        background-color: grayColor950;

        transition: all 200ms ease-in-out;
        &:focus-within
        {
            border-color: greenColor900;

            box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
        }
    }

    .input
    {
        margin: 0;
        outline: none;
        border: none;
        padding: 0;

        background-color: transparent;

        font-size: 0.875rem;
        font-weight: 700;
        color: grayColor100;
    }

    .input-container
    {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }
</style>

<label class="labelled-input { variant }">
    <div class="font-size-75 font-weight-500 color-gray-300">
        { label }
    </div>
    <div class="input-container">
        <input
            class="input"
            { ...{ type } }
            name={ name }
            min={ min }
            max={ max }
            placeholder={ placeholder }
            bind:value={ value }
            on:change={ onChange }
        />
        { #if suffix }
            <div class="font-size-75 font-weight-500 color-gray-300">
                { suffix }
            </div>
        { /if }
    </div>
</label>
