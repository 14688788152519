<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from 'senselogic-gist';

    // -- VARIABLES

    export let goToNextStep;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .onboarding-action
    {
        height: 6rem;
        width: 100%;
        border-top: 1px solid grayColor700;
        padding: 1rem 1.5rem;

        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: center;

        background-color: whiteColor;
    }

    .onboarding-action-button
    {
        border-radius: 0.75rem;
        padding: 1rem 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: blueColor;
    }

    .onboarding-action-button.skip
    {
        padding: unset;

        background-color: unset;
    }
</style>

<div class="onboarding-action">
    <button
        class="font-size-100 font-weight-700 color-gray-400 onboarding-action-button skip"
        on:click={ goToNextStep }
    >
        { getLocalizedTextBySlug( 'onboarding-ignore-step-label' ) }
    </button>
    <button
        class="font-size-100 font-weight-700 color-white onboarding-action-button"
        on:click={ goToNextStep }
    >
        { getLocalizedTextBySlug( 'onboarding-next-label' ) }
    </button>
</div>
