<script>
    // - IMPORTS

    import { createEventDispatcher, onMount } from 'svelte';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { clickOutside, formatPrice } from '$lib/base';
    import { disableScroll, enableScroll } from '$lib/scroll';
    import { languageTagStore } from '$store/languageTagStore';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { totalGuestCounterStore } from '$store/bookingStore';
    import PropertyRules from '$component/page/property/PropertyRules.svelte';
    import PropertyCancellationPolicy from '$component/page/property/PropertyCancellationPolicy.svelte';

    // - VARIABLES

    export let nightCount;
    export let totalPrice;
    export let property;
    export let featureByTypeIdMap;

    let dispatch = createEventDispatcher();

    // - FUNCTIONS

    function closePopup(
        )
    {
        dispatch( 'outsideClick' );
    }

    // ~~

    function formatDate(
        date
        )
    {
        return new Date( date ).toLocaleDateString( $languageTagStore, { month: 'short', day: '2-digit', year: 'numeric' } );
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            disableScroll();
            return () =>
            {
                enableScroll();
            };
        }
    );
</script>

<style lang="stylus">
    // - IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // - CLASSES

    .property-reserve-summary
    {
        z-index: 1000;
        position: fixed;
        top: 6rem;
        top: 50%;
        left: 1.5rem;
        left: 50%;
        transform: translate( -50%, -50% );

        overflow: hidden;
        min-height: 20rem;
        max-height: calc( var( --viewport-height ) - 20rem );
        width: calc( 100% - 3rem );
        border-radius: 1rem;

        display: flex;
        flex-direction: column;

        background-color: grayBackgroundColor;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( desktop )
        {
            width: unset;
        }
    }

    .property-reserve-summary-head
    {
        border-bottom: 1px solid grayColor700;

        display: block;
    }

    .property-reserve-summary-head-container
    {
        padding: 1rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: grayColor900;
    }

    .property-reserve-summary-head-close
    {
        position: absolute;
        right: 1rem;
    }

    .property-reserve-summary-container
    {
        overflow-y: auto;
        padding: 2rem 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .property-reserve-summary-container::-webkit-scrollbar
    {
        display: none;
    }

    .property-reserve-summary-price-rules
    {
        border: 2px solid lightGrayBorderColor;
        border-radius: 1rem;
        padding: 1.25rem 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        background-color: whiteColor;
    }
    .property-reserve-summary-price-rules-group
    {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .property-reserve-summary-price-rules-total-group
    {
        border-top: 2px solid lightGrayBorderColor
        padding-top: 1rem;
    }

    .property-reserve-summary-date
    {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    .property-reserve-summary-date-container
    {
        display: flex;
        gap: 0.75rem;
        align-items: center;
    }

    .property-reserve-summary-date-item
    {
        min-height: 5.875rem;
        min-width: 9.25rem;
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 1.5rem;

        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        background-color: whiteColor;
    }

    .property-reserve-summary-guests
    {
        display: flex;
        flex-direction: column;
        gap: 0.75rem
    }

    .property-reserve-summary-guests-group
    {
        width: 100%;
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;
        padding: 1rem 0.75rem;

        display: flex;
        justify-content: space-between;

        background-color: whiteColor;
    }

    .property-reserve-summary-guests-label
    {
        display: flex;
        gap: 0.75rem;
    }
</style>

<div class="property-reserve-summary" use:clickOutside on:clickOutside={ closePopup }>
    <div class="property-reserve-summary-head">
        <div class="property-reserve-summary-head-container">
            <div class="font-size-100 font-weight-700 color-black property-reserve-summary-head-label">
                { getLocalizedTextBySlug( 'summary-label', $languageTagStore ) }
            </div>
            <button class="gray-close-icon size-75 property-reserve-summary-head-close" on:click={ closePopup }>
            </button>
        </div>
    </div>
    <div class="property-reserve-summary-container">
        <div class="property-reserve-summary-price-rules">
            <div class="property-reserve-summary-price-rules-group">
                <div class="font-size-90 font-weight-500 color-gray-300">
                    { #if nightCount > 1 }
                        { formatPrice( property.shortTermDailyPrice ) }
                        x { nightCount } { getLocalizedTextBySlug( 'nights-label', $languageTagStore ) }
                    { :else }
                        { getLocalizedTextBySlug( 'price-per-night-label', $languageTagStore ) }
                    { /if }
                </div>
                <div class="font-size-90 font-weight-700 color-gray-100">
                    € { totalPrice }
                </div>
            </div>
            <div class="property-reserve-summary-price-rules-group">
                <div class="font-size-90 font-weight-500 color-gray-300">
                    { getLocalizedTextBySlug( 'extra-fees-label', $languageTagStore ) }
                </div>
                <div class="font-size-90 font-weight-700 color-gray-100">
                    -/-
                </div>
            </div>
            <div class="property-reserve-summary-price-rules-group property-reserve-summary-price-rules-total-group">
                <div class="font-size-100 font-weight-700 color-gray-100">
                    { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
                </div>
                <div class="font-size-100 font-weight-700 color-gray-100">
                    { formatPrice( totalPrice ) }
                </div>
            </div>
        </div>

        <div class="property-reserve-summary-date">
            <div class="property-reserve-summary-date-container">
                <div class="property-reserve-summary-date-item">
                    <div class="font-size-75 font-weight-500 color-gray">
                        { getLocalizedTextBySlug( 'date-from-label', $languageTagStore ) }
                    </div>
                    <div class="font-size-90 font-weight-700 color-gray-100">
                        {#if $filterParameterByKeyMapStore.bookingParameters.arrivalDate }
                            { formatDate( $filterParameterByKeyMapStore.bookingParameters.arrivalDate ) }
                        {/if }
                    </div>
                </div>
                <div class="green-right-arrow-icon size-150" />
                <div class="property-reserve-summary-date-item">
                    <div class="font-size-75 font-weight-500 color-gray property-reserve-short-term-date-label">
                        { getLocalizedTextBySlug( 'date-to-label', $languageTagStore ) }
                    </div>
                    <div class="font-size-90 font-weight-700 color-gray-100">
                        {#if $filterParameterByKeyMapStore.bookingParameters.departureDate }
                            { formatDate( $filterParameterByKeyMapStore.bookingParameters.departureDate ) }
                        {/if }
                    </div>
                </div>
            </div>
        </div>
        { #if $totalGuestCounterStore }
            <div class="property-reserve-summary-guests">
                <div class="font-size-100 font-weight-700 color-gray-100">
                    { getLocalizedTextBySlug( 'guests-label', $languageTagStore ) }
                </div>
                <div class="property-reserve-summary-guests-group">
                    <div class="property-reserve-summary-guests-label">
                        <div class="green-guests-icon size-150" />
                        <div class="font-size-90 font-size-500 color-gray-300">
                            { getLocalizedTextBySlug( 'who-label', $languageTagStore ) }
                        </div>
                    </div>
                    <div class="font-size-90 font-weight-900 color-100">
                        { $totalGuestCounterStore } { getLocalizedTextBySlug( 'guests-label', $languageTagStore ) }
                    </div>
                </div>
            </div>
        { /if }
        <PropertyRules featureByTypeIdMap={ featureByTypeIdMap } />
        <PropertyCancellationPolicy featureByTypeIdMap={ featureByTypeIdMap } />
    </div>
</div>
<div class="modal-overlay"></div>
