<script>
    // -- IMPORTS

    import { onMount } from "svelte";
    import { cubicInOut, linear } from 'svelte/easing';
    import { slide, fade } from 'svelte/transition';
    import { getStorageImagePath } from '$lib/storage';
    import { IntervalTimer } from '$lib/intervalTimer';

    // -- VARIABLES

    export let storyTitle = 'Search';
    export let imageArray = [];
    let activeIndex = 0;
    let activeProgress = 0;
    let progressIntervalInMilliseconds = 100;
    let activeInterval;
    let showGallery = false;
    let gallery;

    // -- FUNCTIONS

    function openGallery(
        imageClass
        )
    {
        let galleryImage = document.querySelectorAll( imageClass );

        for ( let image of galleryImage )
        {
            image.addEventListener(
                'click',
                () =>
                {
                    activeIndex = 0;
                    showGallery = true;
                }
                );
        }
    }

    // ~~

    function closeGallery(
        )
    {
        activeIndex = 0;
        activeProgress = 0;
        showGallery = false;
    }

    // ~~

    function nextImage(
        )
    {
        activeProgress = 0;

        if ( activeIndex < imageArray.length - 1 )
        {
            activeIndex = activeIndex + 1;
        }
        else
        {
            closeGallery();
        }
    }

    // ~~

    function prevImage(
        )
    {
        activeProgress = 0;

        if ( activeIndex > 0 )
        {
            activeIndex = activeIndex - 1;
        }
        else
        {
            closeGallery();
        }
    }

    // ~~

    function startActiveProgress(
        )
    {
        if ( activeInterval ) activeInterval.clear();

        activeInterval.start();
    }

    // ~~

     function toggleSlideAnimationPauseResume(
        )
    {
        if ( activeInterval.paused )
        {
            activeInterval.resume();
        }
        else
        {
            activeInterval.pause();
        }
    }

    // ~~

    function getThumbProgressWidth(
        imageIndex
        )
    {
        if ( imageIndex === activeIndex )
        {
            return activeProgress;
        }

        if ( imageIndex < activeIndex )
        {
            return 100;
        }

        return 0;
    }

    // ~~

    onMount(
        () =>
        {
            activeInterval = new IntervalTimer(
            () =>
            {
                let smoothProgressIncrement = 0.83;
                activeProgress += smoothProgressIncrement;

                if ( activeIndex === imageArray.length )
                {
                    nextImage();

                    return;
                }

                if ( activeProgress > 100 )
                {
                    activeIndex += 1;
                    activeProgress = 0;

                    return;
                }
            },
            progressIntervalInMilliseconds
            );

            openGallery( '.gallery-image' );
            startActiveProgress();
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .overlay
    {
        z-index: overlayZIndex;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        height: 100%;
        width: 100%;

        background: grayColorTransparent60;
    }

    .gallery
    {
        position: absolute;
        bottom: 0;

        overflow: hidden;
        max-height: 90%;
        max-width: 100%;
        border-radius: 1.5rem 1.5rem 0 0;

        display: grid;

        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);

        +media( desktop )
        {
            max-height: 100%;
            border-radius: 0;
        }
    }

    .thumbs
    {
        display: flex;
        grid-area: 1 / 1;
        justify-content: center;
    }

    .gallery-items
    {
        height: 100%;
        width: 100%;

        grid-area: 1 / 1;
    }

    .gallery-items img
    {
        height: 100dvh;
        width: 100vw;

        display: none;
        grid-area: 1 / 1;
        object-fit: cover;

        cursor: pointer;

        +media( desktop )
        {
            margin-top: 2rem;

            object-fit: none;
        }
    }

    .gallery-items img.active
    {
        display: initial;
    }

    .gallery-nav
    {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr 1fr;
        grid-area: 1 / 1;
    }

    .thumbs
    {
        display: flex;
    }

    .gallery-nav-header
    {
        margin: 1.25rem 1.5rem;

        grid-column: 1 / 3;
    }

    .thumb,
    .thumb-progress
    {
        height: 0.125rem;
        border-radius: 0.625rem;

        background: whiteColorTransparent40;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }

    img
    {
        max-width: 100%;
        overflow-clip-margin: content-box;
        overflow: clip;
    }

    .thumb
    {
        overflow: hidden;
        margin: 0.25rem 0.3125rem;

        display: flex;
        flex: 1;
        align-items: center;
    }

    .thumb-progress
    {
        width: 0;

        background: whiteColor;
    }

    .gallery-button
    {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba( 0,0,0,0 );
        opacity: 0;
    }

    .gallery-nav-header-title
    {
        margin-bottom: 0.5rem;

        display: flex;
        gap: 0.8em;
    }

    .title
    {
        flex: 1;

        text-align: center;
        text-shadow: 0px 0px 8px 0px rgba( 23,23,23,0.04 );
    }

    .gallery-counter
    {
        position: absolute;
        bottom: 1rem;
        right: 1rem;

        border-radius: 0.75rem;
        padding: 0.5rem 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background: grayColorTransparent60;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }
</style>

{ #if showGallery }
    <div class="overlay" transition:fade={ { duration: 300, easing: cubicInOut } }>
        <div class="gallery" transition:slide={ { duration: 400, axis: 'y', easing: cubicInOut } } bind:this={ gallery }>
            { #key activeIndex }
                <div class="gallery-items" transition:fade={ { duration: 100, easing: linear } }>
                    { #each imageArray as imagePath, imageIndex }
                            <img
                                src={ getStorageImagePath( imagePath, 1280 ) }
                                alt=""
                                class:active={ activeIndex === imageIndex }
                            />
                    { /each }
                </div>
            { /key }

            <nav class="gallery-nav">
                <header class="gallery-nav-header">
                    <div class="gallery-nav-header-title">
                        <span class="font-size-150 font-weight-700 color-white title">{ storyTitle }</span>
                        <button type="button" on:click={ closeGallery }>
                            <div class="white-close-icon size-100"></div>
                        </button>
                    </div>
                    <div class="thumbs">
                        { #each imageArray as _, imageIndex }
                            <div class="thumb">
                                { #key activeProgress }
                                    <div
                                        class="thumb-progress"
                                        style="width: { getThumbProgressWidth( imageIndex ) }%"
                                    />
                                { /key }
                            </div>
                        { /each }
                    </div>
                </header>
                <button class="gallery-button" on:click={ prevImage }>Previous</button>
                <button class="gallery-button" on:click={ nextImage }>Next</button>
            </nav>

            <div class="gallery-counter font-size-90 font-weight-700 color-white">
                { activeIndex + 1 } / { imageArray.length }
            </div>
        </div>
    </div>
{ /if }
