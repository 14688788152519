<script>
    // -- IMPORTS

    import Counter from '$component/element/Counter.svelte';
    import { createEventDispatcher } from 'svelte';

    // -- VARIABLES

    export let count = 0;
    export let maxCount = 10;
    let dispatch = createEventDispatcher();

    // -- FUNCTIONS

    function handleCounterChange(
        { detail }
        )
    {
        if ( detail === 'decrease' )
        {
            count--;
        }
        else
        {
            count++;
        }

        dispatch( 'change', count );
    }
</script>

<Counter
    count={ count }
    minCount={ 0 }
    maxCount={ maxCount }
    on:change={ e => handleCounterChange( e ) }
>
    { count }
</Counter>
