<script>
    // -- IMPORTS

    import { onMount } from 'svelte';
    import { slide } from 'svelte/transition';
    import { getLocalizedText, getLocalizedTextBySlug } from 'senselogic-gist';
    import { fetchData } from '$lib/base';
    import { languageTagStore } from '$store/languageTagStore';

    // -- VARIABLES

    export let featureByTypeIdMap;
    export let featureArray;
    let cancellationPolicy;
    let isLoading = true;
    let isCancellationPolicyListCollapsed = true;
    let cancellationPolicyFeature;

    // -- FUNCTIONS

    async function fetchCancellationPolicyArray(
        )
    {
        let featureCancellationPolicy = featureArray.find( feature => feature.typeId === 'cancellation-policy' );
        let data = await fetchData(
            '/admin/api/cancellation-policy/' + featureCancellationPolicy.value + '/get',
            {
                method: 'POST',
                body: JSON.stringify( { type: 'getCancellationPolicyById' } )
            }
            );

        cancellationPolicy = data.cancellationPolicy;
    }

    // ~~

    function toggleCancellationPolicyCollapsed(
        )
    {
        isCancellationPolicyListCollapsed = !isCancellationPolicyListCollapsed;
    }

    // -- STATEMENTS

    onMount(
        async () =>
        {
            isLoading = true;

            try
            {
                if ( featureByTypeIdMap[ 'cancellation-policy' ] )
                {
                    cancellationPolicyFeature = featureByTypeIdMap[ 'cancellation-policy' ].type;
                    await fetchCancellationPolicyArray();
                }
            }
            catch ( error )
            {
                console.error( error );
            }
            finally
            {
                isLoading = false;
            }
        }
        );
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- ELEMENTS

    li + li
    {
        border-top: 1px solid grayColor800;
    }

    button
    {
        width: fit-content;
    }

    // -- CLASSES

    .property-cancellation-policy-container
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .icon
    {
        flex-shrink: 0;
    }
</style>

{ #if !isLoading && cancellationPolicy }
    <div class="property-cancellation-policy-container">
        <p class="padding-bottom-25 font-size-125 line-height-150 font-weight-600 color-gray-100">
            { getLocalizedText( cancellationPolicyFeature.name, $languageTagStore ) }
        </p>
        { #if !isCancellationPolicyListCollapsed }
            <ul transition:slide>
                {
                    #if cancellationPolicy.partialRefundMinimumDayCount !== null
                    && cancellationPolicy.partialRefundRatio >= 0
                    && cancellationPolicy.partialRefundRatio !== 1
                }
                    <li class="display-flex gap-100 padding-vertical-75 border-color-gray-800 border-style-solid font-size-90 color-gray-100">
                        <div class="icon green-check-icon size-150"/>
                        {
                            getLocalizedTextBySlug(
                                'properties-partial-refund-text',
                                {
                                    percentage: ( 1 - cancellationPolicy.partialRefundRatio ) * 100,
                                    hourCount: cancellationPolicy.partialRefundMinimumDayCount * 24
                                },
                                $languageTagStore
                                )
                        }
                    </li>
                { :else if cancellationPolicy.penaltyDayCount !== null && cancellationPolicy.penaltyDayCount > 0 }
                    <li class="display-flex gap-100 padding-vertical-75 border-color-gray-800 border-style-solid font-size-90 color-gray-100">
                        <div class="icon red-400-x-circle-icon size-150"/>
                        {
                            getLocalizedTextBySlug(
                                'properties-no-refund-text',
                                { hourCount: cancellationPolicy.penaltyDayCount * 24 },
                                $languageTagStore
                                )
                        }
                    </li>
                { /if }
                { #if cancellationPolicy.fullRefundMinimumDayCount !== null && cancellationPolicy.fullRefundMinimumDayCount > 0 }
                    <li class="display-flex gap-100 padding-vertical-75 border-color-gray-800 border-style-solid font-size-90 color-gray-100">
                        <div class="icon green-check-icon size-150"/>
                        {
                            getLocalizedTextBySlug(
                                'properties-cancellation-free-of-charge-text',
                                { hourCount: cancellationPolicy.fullRefundMinimumDayCount * 24 },
                                $languageTagStore
                                )
                        }
                    </li>
                { /if }
            </ul>
        { /if }
        <button
            class="font-size-90 font-weight-700 line-height-125 color-green"
            on:click={ toggleCancellationPolicyCollapsed }
        >
            {
                isCancellationPolicyListCollapsed
                ? getLocalizedTextBySlug( 'show-more-label', $languageTagStore )
                : getLocalizedTextBySlug( 'show-less-label', $languageTagStore )
            }
        </button>
    </div>
{ /if }
