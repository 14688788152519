<script>
    // -- IMPORTS

    import { fetchData, hostUrl } from '$lib/base';
    import { profileSignedInStore } from '$store/profileStore';
    import AccountInput from '$component/page/account/AccountInput.svelte';
    import Error from '../../element/Error.svelte';

    // -- VARIABLES

    export let label;
    export let initialValue;
    export let editable = false;
    export let helper = null;
    let isEditing = false;
    let error = null;
    let isSubmitting = false;

    // -- FUNCTIONS

    function getCamelCasedString(
        string
        )
    {
        return string.replace( /-./g, x => x[ 1 ].toUpperCase() );
    }

    // ~~

    async function handleSubmit(
        event
        )
    {
        event.preventDefault();
        error = null;
        let formData = new FormData( event.target );
        let profileData = {};

        for ( let [ key, value ] of formData )
        {
            let camelCasedKey = getCamelCasedString( key );
            profileData[ camelCasedKey ] = value
        }

        isSubmitting = true;

        let result = await fetch(
            hostUrl + '/api/update-profile/' + $profileSignedInStore.id,
            {
                method: 'POST',
                body: JSON.stringify( profileData ),
                credentials: 'include'
            }
            );

        result = await result.json();
        isSubmitting = false;

        if ( result.profile )
        {
            $profileSignedInStore = result.profile;
            isEditing = false;
        }
        else if ( result.error )
        {
            error = result.error;
        }

        return false;
    }
</script>

{ #key error }
    <Error error={ error } />
{ /key }
<AccountInput
    label={ label }
    initialValue={ initialValue }
    helper={ helper }
    handleSubmit={ handleSubmit }
    isSubmitting={ isSubmitting }
    bind:editable={ editable }
    bind:isEditing={ isEditing }
>
    <slot />
</AccountInput>
