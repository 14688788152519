<script>
    // -- IMPORTS

    import { languageTagStore } from "$store/languageTagStore";
    import { getLocalizedTextBySlug } from "senselogic-gist";
    import Counter from "../ad/Counter.svelte";
    import { formatPrice } from "$lib/base";

    // -- VARIABLES

    export let price;
    export let nightCount;
    export let compensation;
    export let donation;
    export let totalPrice;

    // -- FUNCTIONS

    function handleDonationChange(
        { detail }
        )
    {
        donation =  detail;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import "../../../../mixin.styl";
    @import "../../../../constant.styl";

    // -- CLASSES

    .checkout-page-price-rules
    {
        border-radius: 1rem;
        padding: 1.25rem 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        background-color: whiteColor;
    }

    .checkout-page-price-rules
    {
        width: 100%;
        border: 2px solid grayColor800;

        background-color: grayColor800;

        +media( desktop )
        {
            background-color: grayColor900;
        }
    }

    .checkout-page-price-rules-group
    {
        width: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .checkout-page-price-rules-total-group
    {
        border-top: 1px solid grayColor700;
        padding-top: 1rem;
    }
</style>

<div class="checkout-page-price-rules">
    <div class="checkout-page-price-rules-group">
        <div class="font-size-90 font-weight-500 color-gray-300">
            { formatPrice( price, $languageTagStore ) }
            x { nightCount }
            { getLocalizedTextBySlug( 'nights-label', $languageTagStore ) }
        </div>
        <div class="font-size-90 font-weight-700 color-gray-100">
            { formatPrice( price * nightCount, $languageTagStore ) }
        </div>
    </div>
    <div class="checkout-page-price-rules-group">
        <div class="font-size-90 font-weight-500 color-gray-300">
            { getLocalizedTextBySlug( 'extra-fees-label', $languageTagStore ) }
        </div>
        <div class="checkout-page-price-rules-group-result">
            <div class="font-size-90 font-weight-700 color-gray-100">
                -/-
            </div>
        </div>
    </div>
    <div class="checkout-page-price-rules-group">
        <div class="font-size-90 font-weight-500 color-gray-300">
            { getLocalizedTextBySlug( 'booking-checkout-page-carbon-compensation', $languageTagStore ) }
        </div>
        <div class="font-size-90 font-weight-700 color-gray-100">
            { formatPrice( compensation, $languageTagStore ) }
        </div>
    </div>
    <div class="checkout-page-price-rules-group">
        <div class="font-size-90 font-weight-700 color-gray-100">
            { getLocalizedTextBySlug( 'booking-checkout-page-service-fees', $languageTagStore ) }
        </div>
        <div class="checkout-page-price-rules-group-result">
            <Counter
                count={ donation }
                minCount={ 0 }
                maxCount={ 1000 }
                on:change={ handleDonationChange }
            >
                { formatPrice( donation, $languageTagStore ) }
            </Counter>
        </div>
    </div>
    <div class="checkout-page-price-rules-group checkout-page-price-rules-total-group">
        <div class="font-size-100 font-weight-700 color-gray-100">
            { getLocalizedTextBySlug( 'total-label', $languageTagStore ) }
        </div>
        <div class="font-size-100 font-weight-700 color-gray-100">
            { formatPrice( totalPrice, $languageTagStore ) }
        </div>
    </div>
</div>
