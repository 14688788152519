<script>
    // -- IMPORTS

    import { navigate } from 'svelte-routing';
    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { getStorageImagePath } from '$lib/storage';
    import { getLocalizedMonthYearTextFromDateText } from '$lib/base';
    import { languageTagStore } from '$store/languageTagStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { authModalStore } from '$store/authModalStore';
    import ModalButton from '../../modal/ModalButton.svelte';

    // -- VARIABLES

    export let property;
    export let profile;
    export let profilePropertyArrayLength;

    // -- FUNCTIONS

    function contactHost(
        )
    {
        if ( $profileSignedInStore )
        {
            navigate( '/conversation/' + property.id + '/' + property.userId + '/' + 'contact' );
        }
        else
        {
            $authModalStore = 'sign-in';
        }
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .property-host
    {
        border-top: 1px solid lightGrayBorderColor;
        padding: 1.5rem 0;
    }

    .property-host-head
    {
        margin-bottom: 1rem;

        display: flex;
        justify-content: space-between;
    }

    .property-host-date-properties
    {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .property-host-email-verified
    {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: flex-end;
    }

    .property-host-image img
    {
        height: 3rem;
        width: 3rem;
        border: 2px solid grayColor950;
        border-radius: 3rem;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
    }
</style>

<div class="property-host">
    <div class="property-host-head">
        <div class="property-host-head-container">
            <div class="font-size-100 font-weight-700 color-black property-host-name">
                { #if profile.firstName }
                    { getLocalizedTextBySlug( 'property-host-hosted-by-label', $languageTagStore ) } { profile.firstName }
                { /if }
            </div>
            <div class="property-host-date-properties">
                <div class="font-size-90 font-weight-500 color-gray property-host-date">
                    { getLocalizedTextBySlug( 'property-host-since-label', $languageTagStore ) }
                    { getLocalizedMonthYearTextFromDateText( profile.creationTimestamp, $languageTagStore ) }
                    &#x2022;
                </div>
                <a href="/?profileId={ profile.id }" class="font-size-90 font-weight-700 color-green property-host-properties">
                    { profilePropertyArrayLength }
                    { getLocalizedTextBySlug( 'property-host-properties-label', $languageTagStore ) }
                </a>
            </div>
        </div>
        <div class="property-host-image">
            { #if profile.imagePath }
                <img src={ getStorageImagePath( profile.imagePath, 640 ) } alt={ profile.firstName }/>
            { /if }
        </div>
    </div>
    { #if profile.emailValidationStatusId === 'verified' }
        <div class="property-host-email-verified">
            <div class="green-verified-icon size-150 property-host-email-verified-icon">
            </div>
            <div class="font-size-90 font-weight-500 color-gray property-host-email-verified-label">
                { getLocalizedTextBySlug( 'property-host-verified-user-label', $languageTagStore ) }
            </div>
        </div>
    { /if }
    <div class="margin-top-200">
        <ModalButton
            variant="outlined"
            fullWidth={ false }
            text={ getLocalizedTextBySlug( 'property-host-contact-host-label', $languageTagStore ) }
            on:click={ contactHost }
        />
    </div>
</div>
