<script>
    // -- IMPORTS

    import { Link } from 'svelte-routing';

    // -- CONSTANTS

    export let route;
    export let image;
    export let label;
    export let text;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .mini-card
    {
        width: 100%;
        border: 2px solid lightGrayBorderColor;
        border-radius: 0.75rem;

        background-color: whiteColor;

        transition: all 300ms ease-in-out;
        &:hover
        {
            border-color: greenColor800;

            background-color: greenColor950;

            color: greenColor100;
        }

        +media( desktop )
        {
            border-radius: 1rem;
        }
    }

    .mini-card-container
    {
        width: 100%;
        padding: 0.5rem;

        display: flex;
        gap: 1rem;
        justify-content: center;

        +media( desktop )
        {
            padding: 1rem;
        }
    }

    .mini-card-image
    {
        height: 3rem;
        width: 3rem;
        border-radius: 0.5rem;

        +media( desktop )
        {
            height: 8rem;
            width: 15rem;
            aspect-ratio: 15/8;

            object-fit: cover;
        }
    }

    .mini-card-text-container
    {
        width: 100%;

        display: flex;
        flex-direction: column;
    }
</style>
<div class="mini-card">
    <Link to="{ route }">
        <div class="mini-card-container">
            <img src={ image } class="mini-card-image" alt="{ label } image" />
            <div class="mini-card-text-container">
                <div class="font-size-90 font-weight-700 color-gray-100">
                    { label }
                </div>
                <div class="font-size-75 font-weight-500 color-gray-300">
                    { text }
                </div>
            </div>
        </div>
    </Link>
</div>
