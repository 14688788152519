<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from 'senselogic-gist';
    import { capitalizedCardNameByCardBrandMap, fetchData, getCardBrandByCardNumber } from '$lib/base.js';
    import { languageTagStore } from '$store/languageTagStore';
    import ModalActions from '$component/modal/ModalActions.svelte';
    import ModalButton from '$component/modal/ModalButton.svelte';
    import ModalContent from '$component/modal/ModalContent.svelte';
    import ModalHeader from '$component/modal/ModalHeader.svelte';
    import ModalRoot from '$component/modal/ModalRoot.svelte';
    import AccountInput from '$component/page/account/AccountInput.svelte';

    // -- VARIABLES

    export let isOpen = false;
    export let onClose = () => {};
    export let card = {};
    let isNewCard = Object.values( card ).length === 0;
    $: cardIssuer = getCardBrandByCardNumber( card.number ?? '' );
    let form;
    let isSubmitting = false;

    // -- FUNCTIONS

    async function handleSubmit(
        )
    {
        isSubmitting = true;
        let { cardRegistration }
            = await fetchData(
                '/api/create-card-registration',
                {
                    method: 'POST',
                    credentials: 'include'
                }
                );

        let response
            = await fetch(
                cardRegistration.CardRegistrationURL,
                {
                    method: 'POST',
                    body: new URLSearchParams(
                        {
                            accessKeyRef: cardRegistration.AccessKey,
                            data: cardRegistration.PreregistrationData,
                            cardNumber: '4970105181818183',
                            cardExpirationDate: card.expirationDate.split( '/' ).join( '' ),
                            cardCvx: card.cvv
                        }
                        )
                        .toString(),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }
                );

        let cardData = await response.text();
        cardRegistration.RegistrationData = cardData;

        let cardResult
            = await fetchData(
                '/api/update-card-registration',
                {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify( { cardRegistration: cardRegistration } )
                }
                );

        isSubmitting = false;
        onClose( cardResult.card );
        isOpen = false;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';

    // -- CLASSES

    .account-banking-information-card-content
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: stretch;
    }

    .account-banking-information-card-content.wait
    {
        cursor: wait;
    }

    .account-banking-information-card-field
    {
        border-bottom: 1px solid grayColor800;
        padding: 0.75rem 0px;

        display: flex;
        gap: 0.75rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }

    .account-banking-information-card-field-label
    {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: 0.375rem;
        align-items: flex-start;
    }

    .account-banking-information-card-field-input
    {
        outline: none;
        width: 100%;
        border: none;
    }

    .account-banking-information-make-default-button,
    .account-banking-information-delete-button
    {
        height: 3.5rem;
        border: 1px solid blueColor;
        border-radius: var(--Rounded-Corners-Btns, 8px);
        padding: 0.75rem 2.5rem;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        align-self: stretch;

        background: grayColor900;
    }

    .account-banking-information-credit-card-box
    {
        height: 12.5rem;
        width: 21.5rem;
        border: 2px solid grayColor800;
        border-radius: 1rem;
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: flex-start;

        background: colorWhite;
        box-shadow: 1px 1px 8px 0px rgba(23, 23, 23, 0.08);
    }

    .account-banking-information-credit-card-box-text
    {
        width: 100%;

        text-align: right;
    }

    .account-banking-information-edit-credit-card-field
    {
        border-bottom: 1px solid grayColor800;
        padding: 0.75rem 0px;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: flex-start;
        align-self: stretch;
    }

    .account-banking-information-edit-credit-card-number
    {
        border: 2px solid colorGray800;
        border-radius: 0.75rem;
        padding: 0.5rem 0.75rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        align-self: stretch;

        background: grayColor950;
    }

    .account-banking-information-delete-button
    {
        border: none;

        color: redColor300;
    }
</style>

<ModalRoot isOpen={ isOpen }>
    <ModalHeader
        title={
            getLocalizedTextBySlug(
                isNewCard
                ? 'banking-information-card-modal-new-card-label'
                : 'banking-information-card-modal-edit-card-label',
                $languageTagStore
                )
            }
        onClose={ onClose }
    />
    <ModalContent>
        { #if isNewCard }
            <form
                class="account-banking-information-card-content"
                class:wait={ isSubmitting }
                on:submit|preventDefault={ handleSubmit }
                bind:this={ form }
            >
                <div class="account-banking-information-card-field">
                    <div class="account-banking-information-card-field-label font-size-100 font-weight-500 color-black">
                        <label for="cardNumber">
                            { getLocalizedTextBySlug( 'banking-information-card-modal-card-number-label', $languageTagStore ) }
                        </label>
                        <input
                            value={ '4970107111111119' }
                            id="cardNumber"
                            name="cardNumber"
                            class="account-banking-information-card-field-input"
                            type="text"
                            on:keydown|preventDefault
                        >
                    </div>
                    <div class="{ cardIssuer }-icon size-250"/>
                </div>
                 <div class="account-banking-information-card-field">
                    <div class="account-banking-information-card-field-label font-size-100 font-weight-500 color-black">
                        <label for="cardHolder">
                            { getLocalizedTextBySlug( 'banking-information-card-modal-holder-name-label', $languageTagStore ) }
                        </label>
                        <input
                            bind:value={ card.holder }
                            id="cardHolder"
                            name="cardHolder"
                            class="account-banking-information-card-field-input"
                            type="text"
                        >
                    </div>
                </div>
                <div class="account-banking-information-card-field">
                    <div class="account-banking-information-card-field-label font-size-100 font-weight-500 color-black">
                        <label for="expiryDate">
                            { getLocalizedTextBySlug( 'banking-information-card-modal-expiry-date-label', $languageTagStore ) }
                        </label>
                        <input
                            bind:value={ card.expirationDate }
                            id="expiryDate"
                            name="expiryDate"
                            class="account-banking-information-card-field-input"
                            type="text"
                        >
                    </div>
                </div>
                 <div class="account-banking-information-card-field">
                    <div class="account-banking-information-card-field-label font-size-100 font-weight-500 color-black">
                        <label for="cardCvv">CVV</label>
                        <input
                            bind:value={ card.cvv }
                            id="cardCvv"
                            name="cardCvv"
                            class="account-banking-information-card-field-input"
                            type="password"
                            minlength="3"
                            maxlength="3"
                        >
                    </div>
                </div>
            </form>
            <button class="account-banking-information-make-default-button font-size-100 font-weight-700 color-blue">
                { getLocalizedTextBySlug( 'banking-information-card-modal-make-default-label', $languageTagStore ) }
            </button>
        { :else }
            <div class="account-banking-information-credit-card-box">
                <div class="{ cardIssuer }-icon size-250"/>
                <span class="account-banking-information-credit-card-box-text font-size-100 font-weight-500 color-gray-100">
                    { capitalizedCardNameByCardBrandMap[ cardIssuer ] }
                </span>
            </div>
            <div class="account-banking-information-edit-credit-card-field">
                <span class="account-banking-information-credit-card-field-label font-size-90 font-weight-500 color-gray-100">
                    { getLocalizedTextBySlug( 'banking-information-card-modal-card-number-label', $languageTagStore ) }
                </span>
                <div class="account-banking-information-edit-credit-card-number">
                    <span class="font-size-75 font-weight-500 color-gray-300">
                        { getLocalizedTextBySlug( 'banking-information-card-modal-number-label', $languageTagStore ) }
                    </span>
                    <span class="font-size-90 font-weight-700 color-gray-100">
                        { card.number }
                    </span>
                </div>
                <span class="font-size-75 font-weight-500 color-gray-300">
                    { getLocalizedTextBySlug( 'banking-information-card-modal-make-sure-correct-card-number-label', $languageTagStore ) }
                </span>
            </div>
            <div class="account-banking-information-card-content">
                <AccountInput
                    label={ getLocalizedTextBySlug( 'banking-information-card-modal-holder-name-label', $languageTagStore ) }
                    initialValue={ card.holder }
                    editable={ true }
                >
                    <input bind:value={ card.holder } name="cardNumber" class="account-banking-information-card-field-input">
                </AccountInput>
                <AccountInput
                    label={ getLocalizedTextBySlug( 'banking-information-card-modal-expiry-date-label', $languageTagStore ) }
                    initialValue={ card.expiryDate }
                    editable={ true }
                >
                    <input bind:value={ card.expiryDate } name="expiryDate" class="account-banking-information-card-field-input">
                </AccountInput>
                <AccountInput
                    label={ 'CVV' }
                    initialValue={ '•••' }
                    editable={ true }
                >
                    <input
                        bind:value={ card.cvv }
                        name="cardCvv"
                        class="account-banking-information-card-field-input"
                        type="password"
                        minlength="3"
                        maxlength="3"
                    >
                </AccountInput>
            </div>
            <button class="account-banking-information-make-default-button font-size-100 font-weight-700 color-blue">
                { getLocalizedTextBySlug( 'banking-information-card-modal-make-default-label', $languageTagStore ) }
            </button>
            <button class="account-banking-information-delete-button font-size-100 font-weight-700">
                { getLocalizedTextBySlug( 'banking-information-card-modal-delete-label', $languageTagStore ) }
            </button>
        { /if }
    </ModalContent>
    <ModalActions>
        <ModalButton
            variant="light"
            text={ getLocalizedTextBySlug( 'onboarding-skip-label', $languageTagStore ) }
            disabled={ isSubmitting }
            on:click={ onClose }
        />
        <ModalButton
            variant="contained"
            text={ getLocalizedTextBySlug( 'save-label', $languageTagStore ) }
            on:click={ () => form.requestSubmit() }
            isLoading={ isSubmitting }
        />
    </ModalActions>
</ModalRoot>
