<script>
    // -- IMPORTS

    import { getLocalizedTextBySlug } from "senselogic-gist";
    import EditLeaseContractPageSection from "../edit-lease-contract/EditLeaseContractPageSection.svelte";
    import UserReviewCard from "./UserReviewCard.svelte";
    import { languageTagStore } from "$src/lib/store/languageTagStore";

    // -- VARIABLES

    export let userReviewArray;
    export let userReviewProfileByUserIdMap;
</script>

<style lang="stylus">
    // -- CLASSES

    .user-review-list-container
    {
        overflow: scroll;
        scrollbar-width: none;
        width: 100%;

        display: flex;
        gap: 1rem;
    }
</style>

<EditLeaseContractPageSection
    label=
    "
        { getLocalizedTextBySlug( 'account-statistics-commentaires-label', $languageTagStore ) }
        ( { userReviewArray.length } )
    "
    helper={ getLocalizedTextBySlug( 'filter-short-term-label', $languageTagStore ) }
>
    <div class="user-review-list-container">
        { #each userReviewArray as userReview }
            <UserReviewCard
                userReview={ userReview }
                profile={ userReviewProfileByUserIdMap[ userReview.userId ] }
            />
        { /each }
    </div>
</EditLeaseContractPageSection>
