<script>
    // -- IMPORTS

    import { slide } from 'svelte/transition';
    import { navigate } from 'svelte-routing';
    import { getLocalizedTextBySlug, getRandomTuid } from 'senselogic-gist';
    import { countNightsBetweenArrivalDateAndDepartureDate } from '$lib/booking';
    import { authModalStore } from '$store/authModalStore';
    import { profileSignedInStore } from '$store/profileStore';
    import { languageTagStore } from '$store/languageTagStore';
    import { filterParameterByKeyMapStore } from '$store/filterParameterByKeyMapStore';
    import { bookedPropertyStore, guestCounterStore, totalGuestCounterStore } from '$store/bookingStore';
    import PropertyReserveTerms from '$component/page/property/PropertyReserveTerms.svelte';
    import PropertyReserveSummary from '$component/page/property/PropertyReserveSummary.svelte';
    import PropertyVerifyIdentityModal from '$component/page/property/PropertyVerifyIdentityModal.svelte';

    // -- VARIABLES

    export let property;
    export let cityName;
    export let visit = null;
    export let isScheduleVisitModalOpen = false;
    export let featureByTypeIdMap;
    export let propertyLeaseContract;
    export let profileDocumentByTypeIdMap = {};
    export let rentalBookingArray = [];
    let showPropertySummaryPopup = false;
    let isVerifyIdentityModalOpen = false;
    let isReserveTermsOpen = false;

    // -- FUNCTIONS

    function goToBooking(
        )
    {
        if( !profileSignedInStore )
        {
            $authModalStore = 'sign-in';
        }
        else if ( !profileDocumentByTypeIdMap[ 'id-card' ] )
        {
            isVerifyIdentityModalOpen = true;
        }
        else
        {
            if ( shortTermNightsCount > 0 )
            {
                $bookedPropertyStore = { ...property, cityName: cityName }
                $guestCounterStore =
                {
                    adult: $filterParameterByKeyMapStore.bookingParameters.adultCount,
                    children: $filterParameterByKeyMapStore.bookingParameters.childrenCount,
                    infant: $filterParameterByKeyMapStore.bookingParameters.infantCount,
                    pet: $filterParameterByKeyMapStore.bookingParameters.petCount ?? 0
                }
                navigate( '/booking/checkout/' + getRandomTuid() );
            }
        }
    }

    // -- STATEMENTS

    $: $guestCounterStore =
        {
            adult: $filterParameterByKeyMapStore.bookingParameters.adultCount ?? 0,
            children: $filterParameterByKeyMapStore.bookingParameters.childrenCount ?? 0,
            infant: $filterParameterByKeyMapStore.bookingParameters.infantCount ?? 0,
            pet: $filterParameterByKeyMapStore.bookingParameters.petCount ?? 0
        }

    // ~~

    $: shortTermNightsCount
        = countNightsBetweenArrivalDateAndDepartureDate(
            $filterParameterByKeyMapStore.bookingParameters.arrivalDate,
            $filterParameterByKeyMapStore.bookingParameters.departureDate
            );

    // ~~

    $: shortTermTotalPrice =
        shortTermNightsCount > 1
        ? shortTermNightsCount * property.shortTermDailyPrice
        : property.shortTermDailyPrice;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .property-footer-reserve
    {
        z-index: 400;
        position: fixed;
        bottom: 4rem;
        left: 0;

        width: 100%;
        border-top: 1px solid grayColor700;
        padding: 1rem 1.5rem;

        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;

        background: grayColor900;

        +media( desktop )
        {
            display: none;
        }
    }

    .property-footer-reserve-button
    {
        border-radius: 0.5rem;
        padding: 1.2rem 2.5rem;

        background: blueColor;
    }

    .property-footer-reserve-summary
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .property-reserve-terms-modal
    {
        z-index: 500;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        overflow: auto;
        height: 100%;
        width: 100%;
        padding: 1rem;

        display: flex;
        justify-content: center;

        background: white;
    }
</style>

{ #if isReserveTermsOpen }
    <dialog class="property-reserve-terms-modal" transition:slide>
        <PropertyReserveTerms
            { property }
            { cityName }
            bind:visit={ visit }
            bind:isScheduleVisitModalOpen={ isScheduleVisitModalOpen }
            { featureByTypeIdMap }
            { propertyLeaseContract }
            { profileDocumentByTypeIdMap }
            { rentalBookingArray }
            bind:modal={ isReserveTermsOpen }
            bind:showPropertySummaryPopup={ showPropertySummaryPopup }
        />
    </dialog>
{ /if }
<PropertyReserveTerms
    { property }
    { cityName }
    { featureByTypeIdMap }
    { propertyLeaseContract }
    { profileDocumentByTypeIdMap }
    { rentalBookingArray }
    bind:visit={ visit }
    bind:isScheduleVisitModalOpen={ isScheduleVisitModalOpen }
    bind:showPropertySummaryPopup={ showPropertySummaryPopup }
/>
<footer class="property-footer-reserve">
    <button
        class="property-footer-reserve-summary"
        on:click={ () => isReserveTermsOpen = true }
    >
        <span class="font-size-100 color-blue font-weight-700">
            € { property.shortTermDailyPrice } &#x2022; € { property.longTermMonthlyPrice }
        </span>
        <span class="font-size-75 color-gray-300">
            { #if $filterParameterByKeyMapStore.bookingParameters.arrivalDate }
                { $filterParameterByKeyMapStore.bookingParameters.arrivalDate }
            { /if }
            { #if $filterParameterByKeyMapStore.bookingParameters.departureDate }
                - { $filterParameterByKeyMapStore.bookingParameters.departureDate }
            { /if }
            { #if $totalGuestCounterStore }
                &#x2022; { $totalGuestCounterStore } { getLocalizedTextBySlug( 'filter-guests-label', $languageTagStore ) }
            { /if }
       </span>
    </button>
    <button
        class="property-footer-reserve-button"
        disabled={ $profileSignedInStore?.userId === property.userId }
        on:click={ goToBooking }
    >
        <span class="font-size-100 font-weight-700 color-white">
            { getLocalizedTextBySlug( 'reserve-label', $languageTagStore ) }
        </span>
    </button>
</footer>
<PropertyVerifyIdentityModal bind:isOpen={ isVerifyIdentityModalOpen } />
{ #if showPropertySummaryPopup }
    <PropertyReserveSummary
        on:outsideClick={ () => ( showPropertySummaryPopup = !showPropertySummaryPopup ) }
        property={ property }
        totalPrice={ shortTermTotalPrice }
        nightCount={ shortTermNightsCount }
        featureByTypeIdMap={ featureByTypeIdMap }
    />
{ /if }
