<script>
    // -- VARIABLES

    export let label = '';
    export let isActive = false;
    export let elementReference = null;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- CLASSES

    .tab
    {
        border: 1px solid transparent;
        border-radius: 1.5rem;
        padding: 0.75rem 1rem;

        display: flex;
        justify-content: center;
        align-items: center;

        background: grayColor950;

        white-space: nowrap;

        cursor: pointer;
        transition: all 400ms ease-in-out;
        &:hover
        {
            border-color: grayColor600;

            background-color: whiteColor;
            .tab-label
            {
                color: grayColor600;
            }
        }
    }

    .tab.is-active,
    .tab.is-active:hover
    {
        border-color: transparent;

        background-color: greenColor800;
        .tab-label
        {
            color: greenColor100;
        }
    }
</style>

<button
    bind:this={ elementReference }
    class="tab"
    class:is-active={ isActive }
    on:click
>
    <div class="font-size-75 font-weight-600 color-gray-600 tab-label">
        { label }
    </div>
</button>
