<script>
    // -- IMPORTS

    import { fade } from 'svelte/transition';
    import { getLocalizedText } from 'senselogic-gist';
    import { getStorageImagePath } from '$lib/storage';
    import { getLocalizedMonthDayYearTextFromDateText } from '$lib/base';
    import { languageTagStore } from '$store/languageTagStore';

    // -- VARIABLES

    export let propertyRentalReview;
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../../constant.styl';
    @import '../../../../mixin.styl';

    // -- CLASSES

    .property-rental-review-card
    {
        overflow: hidden;
        margin: auto;
        height: 15rem;
        width: calc(100% - 0.75rem);
        min-width: 15rem;
        border: 1px solid grayColor700;
        border-radius: 1rem;
        padding: 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        background-color: grayColor950;
    }

    .property-rental-review-card-head
    {
        min-height: 4rem;

        display: flex;
        flex-direction: row;
        gap: 0.75rem;
    }

    .property-rental-review-card-head-image
    {
        height: 3rem;
        width: 3rem;
        border: 2px solid grayColor950;
        border-radius: 3rem;

        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
    }

    .property-rental-review-card-head-profile-name
    {
        max-width: 9.5rem;

        white-space: nowrap;
    }

    .property-rental-review-card-text,
    .property-rental-review-card-head-profile-name
    {
        overflow: hidden;

        text-overflow: ellipsis;
    }
</style>

<div class="property-rental-review-card" transition:fade>
    <div class="property-rental-review-card-head">
        <img
            class="property-rental-review-card-head-image"
            src={ getStorageImagePath( propertyRentalReview.profileImagePath, 640 ) }
            alt="{ propertyRentalReview.profileFirstName } { propertyRentalReview.profileLastName }"
        />
        <div class="property-rental-review-card-head-profile">
            <div class="font-size-100 font-weight-700 color-black property-rental-review-card-head-profile-name">
                { propertyRentalReview.profileFirstName } { propertyRentalReview.profileLastName }
            </div>
            <div class="font-size-90 font-weight-500 color-gray property-rental-review-card-head-profile-date">
                { getLocalizedMonthDayYearTextFromDateText( propertyRentalReview.creationTimestamp ) }
            </div>
        </div>
    </div>
    <div class="font-size-90 font-weight-500 color-black property-rental-review-card-text">
        { getLocalizedText( propertyRentalReview.text, $languageTagStore ) }
    </div>
</div>
